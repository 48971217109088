import { PaymentsRecord } from "@api/types";
import { useGetPaymentSummary } from "@api/useGetPaymentSummary";
import PaymentDetailInfo from "@components/paymentInfoCard";
import { theme } from "@styles/themes";
import { paymentMethodText } from "@utils/payments";
import React from "react";
import { useForm } from "react-hook-form";
import DateRangePicker from "@components/calendar";
import { useCalendar } from "@context/CalendarContext";
import Heading from "@components/font/heading";
import { useTranslation } from "react-i18next";

const PaymentSeeMore = () => {
  const { t } = useTranslation();
  const { data } = useGetPaymentSummary();
  const [visiblePayments, setVisiblePayments] = React.useState<
    PaymentsRecord[]
  >([]);
  const [initialData, setInitialData] = React.useState<PaymentsRecord[]>([]);
  const {startDate, endDate, resetData } = useCalendar();

  const { watch } = useForm({
    defaultValues: {
      search: "",
    },
  });
  const watchSearch = watch("search");

  // effects
  React.useEffect(() => {
    let list = data.paymentsRecord;

    if (watchSearch) {
      list = list?.filter((item) =>
        item?.paymentAmount?.toString().includes(watchSearch)
      );
    }

    setVisiblePayments(list);
    setInitialData(list);
  }, [watchSearch, data]);


  React.useEffect(() => {
    if (startDate && endDate) {
      const startTimestamp = startDate.getTime() / 1000;
      const endTimestamp = endDate.getTime() / 1000;
  
      const filtered = initialData.filter(
        (item) =>
          item.uploadTimestamp >= startTimestamp &&
          item.uploadTimestamp <= endTimestamp
      );
  
      setVisiblePayments(filtered);
    }else {
      setVisiblePayments(initialData);
    }
  }, [startDate, endDate]);

    React.useEffect(() => {
    return () => {
      resetData();
    };
    }, []); 

  return (
    <div style={{ marginBottom: "5em", padding:'0px 16px' }}>
      <div className="d-block d-md-none" style={{marginTop:'24px'}}>
        <DateRangePicker />
      </div>
      {visiblePayments.length
        ? visiblePayments?.map((elem, index) => (
            <PaymentDetailInfo
              chipBg={theme.colors.grey.dark}
              key={index}
              amount={elem.paymentAmount}
              paymentMethod={paymentMethodText[elem.method]}
              timestamp={elem.uploadTimestamp}
              status={elem.status}
              invoiceUrl={elem.invoiceUrl}
            />
          ))
        : <Heading
        fontSize="17px"
        title={t("payments.thereIsNoPayments")}
        fontWeight="400"
        color={theme.colors.white.dark}
        style={{marginTop:'24px'}}
      />}
    </div>
  );
};

export default PaymentSeeMore;
