import Heading from "@components/font/heading";
import { theme } from "@styles/themes";
import React from "react";
import { Offcanvas } from "react-bootstrap";
import FilterSection from "./filterSection";
import { useForm } from "react-hook-form";
import { Button } from "@components/button/button";
import { FilterType, useStatsChart } from "@context/StatsChartContext";
import IconButtonHover from "@components/button/Iconbutton";
import cross from "@assets/icon/x.svg";
import { useTranslation } from "react-i18next";

const DrawerFilter = ({
  setShow,
  show,
  filterType,
}: {
  setShow: (value: boolean) => void;
  show: boolean;
  filterType: FilterType;
}) => {
  const { control } = useForm({
    defaultValues: {
      search: "",
    },
  });
  const { t } = useTranslation();
  const { clearFilters, applyFilters } = useStatsChart();

  const handleClose = () => setShow(false);

  const handleApplyFilters = () => {
    applyFilters(filterType);
    handleClose();
  };

  const handleCleanFilters = () => {
    clearFilters(filterType);
    handleClose();
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        style={{ background: theme.colors.grey.darker,
          borderLeft: window.innerWidth < 770 ? "" :`0.5px solid ${theme.colors.white.dark}`,
          width: window.innerWidth < 770 ? "100%" : "400px",
          maxWidth: window.innerWidth < 770 ? "100%" : "400px",
        }}
      >
        <Offcanvas.Header
          style={{
            borderBottom: `0.5px solid ${theme.colors.white.dark}`,
            padding: "7px 16px",
          }}
        >
          <div className="d-flex justify-content-between align-items-center w-100">
            <div>
              <Heading
                fontSize="20px"
                title="profile.filter"
                fontWeight="700"
                color={theme.colors.white.normal}
              />
            </div>
            <div>
              <IconButtonHover onClick={handleClose} src={cross} alt="cross" />
            </div>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <FilterSection control={control} filterType={filterType} />
        </Offcanvas.Body>
        <div
          style={{ borderTop: `0.5px solid ${theme.colors.white.dark}` }}
        >
          <div
            className="d-flex justify-content-between align-items-center w-100"
            style={{ padding: "16px 24px", gap: 16 }}
          >
            <Button
              title={t("profile.cleanFilters")}
              outline
              onClick={handleCleanFilters}
              style={{
                width: "100%",
                fontWeight: "700",
                fontSize: "14px",
                borderRadius: "8px",
              }}
            />
            <Button
              title={t("profile.applyFilters")}
              onClick={handleApplyFilters}
              style={{
                width: "100%",
                fontWeight: "700",
                fontSize: "14px",
                borderRadius: "8px",
              }}
            />
          </div>
        </div>
      </Offcanvas>
    </>
  );
};

export default DrawerFilter;
