import { AddAddressRequest, useAddAddress } from "@api/useAddAddress";
import Heading from "@components/font/heading";
import { RadioButton } from "@components/form/radioButton";
import { SearchSelectBox } from "@components/form/searchSelect";
import { TextField } from "@components/form/textField";
import { theme } from "@styles/themes";
import { appCountries } from "@utils/countries";
import { City, ICountry } from "country-state-city";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { Checkbox, CheckboxWrapper, Label } from "../../signUp/steps/mobile/StepFive";
import { useTranslation } from "react-i18next";
import { Button } from "@components/button/button";
import { useNavigate } from "react-router-dom";
import { regexPatterns } from "@utils/regex";
import { toast } from "react-toastify";

const StyledContainer = styled.div`
  margin-bottom: 16px;
  max-width: 600px;
  @media (max-width: 768px) {
    min-width: 100%; /* Full width on mobile */
  }
`;
const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCol = styled(Col)`
  @media (max-width: 768px) {
    display: none;
  }
`;
const StyledMobileBtn = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;
const StyledButton = styled(Button)`
  font-size: 14px;
  font-weight: 700;
  border-radius: 8px;
  min-width: 3em;
  width: auto;
  margin-top: 16px;
  margin: 0;

  @media (max-width: 768px) {
    display: none;
  }
`;

type AddressForm = AddAddressRequest & {
  radio_select: string;
  phonePrefix: any;
};

const mainOptions = appCountries?.map((country: ICountry) => ({
  value: country.isoCode,
  label: country.name,
}));

const AddForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutate } = useAddAddress({
    onSuccess: () => {
      toast.success(t("success.billingDetailAdded"));
      navigate(-1);
    },
  });

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    trigger,
    formState: { errors },
  } = useForm<AddressForm>({
    defaultValues: {
      isCompany: false, // set isCompany default to false
    },
  });

  const onSubmit = (data) => {
    const formData = {
      companyName: data?.companyName || "",
      name: data?.name,
      surname: data?.surname,
      country: data?.country,
      city: data?.city,
      address: data?.address,
      addressExtra: data?.addressExtra || "",
      zipCode: data?.zipCode,
      VAT: data?.vat,
      personId: data?.nif,
      NIF: data?.nif,
      isCompany: data?.isCompany == true ? data?.isCompany : false,
      isDefault: data?.isSaved,
    };
    mutate(formData);
  };

  const watchCountry = watch("country");
  const watchCity = watch("city");
  const watchIsCompany = watch("isCompany");

  const city = City.getCitiesOfCountry(getValues("country"));

  const mainCity = city?.map((citys: any) => ({
    value: citys.stateCode,
    label: citys.name,
  }));

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StyledContainer>
        <Row className="g-4">
          <Col md={6} sm={6} xs={6}>
            <div
              className="d-flex align-items-center"
              onClick={() => setValue("isCompany", false)}
            >
              <RadioWrapper>
                <RadioButton
                  name="isCompany"
                  value={false}
                  checked={watch("isCompany") === false}
                  {...control.register("isCompany", {
                    required: false,
                  })}
                />
              </RadioWrapper>

              <div className="mx-3" style={{ cursor: "pointer" }}>
                <Heading
                  fontSize="14px"
                  title={t("profile.individual")}
                  fontWeight="400"
                  color={theme.colors.white.normal}
                />
              </div>
            </div>
          </Col>
          <Col md={6} sm={6} xs={6}>
            <div
              className="d-flex align-items-center"
              onClick={() => setValue("isCompany", true)}
            >
              <RadioWrapper>
                <RadioButton
                  name="isCompany"
                  value={true}
                  checked={watch("isCompany") === true}
                  {...control.register("isCompany", {
                    required: false,
                  })}
                />
              </RadioWrapper>

              <div className="mx-3 " style={{ cursor: "pointer" }}>
                <Heading
                  fontSize="14px"
                  title={t("profile.isCompany")}
                  fontWeight="400"
                  color={theme.colors.white.normal}
                />
              </div>
            </div>
          </Col>

          {watchIsCompany === false && (
            <>
              <Col md={12} sm={12} xs={12}>
                <TextField
                  floatingLabel
                  control={control}
                  name="name"
                  bgColor={theme.colors.grey.dark}
                  size="sm"
                  placeholder={t("profile.name")}
                  onBlur={(e) => {
                    trigger("name");
                  }}
                  errors={errors.name}
                  rules={{
                    required: t("errors.required"),
                    pattern: {
                      value: regexPatterns?.regOnlyChar,
                      message: t("errors.namePattern"),
                    },
                  }}
                />
              </Col>

              <Col md={12} sm={12} xs={12}>
                <TextField
                  floatingLabel
                  control={control}
                  name="surname"
                  onBlur={(e) => {
                    trigger("surname");
                  }}
                  bgColor={theme.colors.grey.dark}
                  size="sm"
                  placeholder={t("profile.surname")}
                  errors={errors.surname}
                  rules={{
                    required: t("errors.required"),
                    pattern: {
                      value: regexPatterns?.regOnlyChar,
                      message: t("errors.namePattern"),
                    },
                  }}
                />
              </Col>
            </>
          )}
          {watchIsCompany === true && (
            <Col md={12} sm={12} xs={12}>
              <TextField
                floatingLabel
                control={control}
                name="companyName"
                bgColor={theme.colors.grey.dark}
                size="sm"
                onBlur={(e) => {
                  trigger("companyName");
                }}
                placeholder="Company Name"
                errors={errors.companyName}
                rules={{
                  required: t("errors.required"),
                  pattern: {
                    value: regexPatterns?.regTextWithNumbers,
                    message: t("errors.namePattern"),
                  },
                }}
              />
            </Col>
          )}
          <Col md={6} sm={6} xs={6}>
            <SearchSelectBox
              bgColor={theme.colors.grey.dark}
              size="sm"
              selectValue="isoCode"
              selectLabel="name"
              options={mainOptions}
              placeholder={t("profile.country")}
              control={control}
              name="country"
              setValue={setValue}
              errors={errors.country}
              value={watchCountry}
              rules={{ required: t("errors.required") }}
            />
          </Col>
          <Col md={6} sm={6} xs={6}>
            <SearchSelectBox
              bgColor={theme.colors.grey.dark}
              size="sm"
              options={mainCity}
              selectValue="stateCode"
              selectLabel="name"
              placeholder={t("profile.city")}
              control={control}
              name="city"
              setValue={setValue}
              errors={errors.city}
              value={watchCity}
              rules={{ required: t("errors.required") }}
            />
          </Col>
          <Col md={12} sm={12} xs={12}>
            <TextField
              floatingLabel
              control={control}
              name="address"
              onBlur={(e) => {
                trigger("address");
              }}
              bgColor={theme.colors.grey.dark}
              size="sm"
              placeholder={t("profile.address")}
              errors={errors.address}
              rules={{ required: t("errors.required") }}
            />
          </Col>

          <Col md={12} sm={12} xs={12}>
            <TextField
              floatingLabel
              control={control}
              name="addressExtra"
              bgColor={theme.colors.grey.dark}
              size="sm"
              placeholder={t("profile.additionalAddress")}
              rules={{ required: false }}
            />
          </Col>

          <Col md={3} sm={3} xs={5}>
            <TextField
              floatingLabel
              control={control}
              name="zipCode"
              bgColor={theme.colors.grey.dark}
              size="sm"
              onBlur={(e) => {
                trigger("zipCode");
              }}
              placeholder={t("profile.zipCode")}
              errors={errors.zipCode}
              rules={{
                required: t("errors.required"),
                pattern: {
                  value: regexPatterns?.zipCodeRegex,
                  message: t("errors.zipCode"),
                },
              }}
            />
          </Col>

          <Col md={9} sm={9} xs={7}>
            {watchIsCompany ? (
              <TextField
                floatingLabel
                control={control}
                name={"vat"}
                bgColor={theme.colors.grey.dark}
                size="sm"
                placeholder={"Tax ID*"}
                onBlur={(e) => {
                  trigger("vat");
                }}
                errors={errors.vat}
                rules={{ required: t("errors.required") }}
              />
            ) : (
              <TextField
                floatingLabel
                control={control}
                name={"nif"}
                onBlur={(e) => {
                  trigger("nif");
                }}
                bgColor={theme.colors.grey.dark}
                size="sm"
                placeholder={"TIN*"}
                errors={errors.nif}
                rules={{ required: t("errors.required") }}
              />
            )}
          </Col>
          <Col md={12}>
            <CheckboxWrapper>
              <Checkbox
                {...control.register("isSaved", {
                  required: false,
                })}
              />
              <Label htmlFor="save_address">{t("payments.setDefault")}</Label>
            </CheckboxWrapper>
          </Col>
          <StyledCol md={12}>
            <StyledButton title="actions.save" type="submit" />
          </StyledCol>
        </Row>
      </StyledContainer>
      <StyledMobileBtn
        style={{ position: "fixed", right: 0, left: 0, bottom: 0 }}
      >
        <div
          className="d-flex align-items-center"
          style={{
            padding: "16px 24px",
            borderTop: `0.5px solid ${theme.colors.grey.lightActive}`,
          }}
        >
          <Button
            title="profile.add"
            outline={false}
            style={{ fontSize: "14px", fontWeight: "700", width: "100%" }}
          />
        </div>
      </StyledMobileBtn>
    </form>
  );
};

export default AddForm;
