import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "@components/button/button";
import Heading from "@components/font/heading";
import { theme } from "@styles/themes";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import { useSignUp } from "@api/useSignUp";
import AlertModal from "@components/modal/AlertModal";
import FullScreenLoader from "@components/loader/Loader";

const StepperWeb = () => {
  const { t } = useTranslation();
  const [step, setStep] = React.useState<any>(1);
  const navigate = useNavigate();
  
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phonePrefix: "",
      phoneNumber: "",
      nickname: "",
      telegramUser: "",
      password: "",
      confirmPassword: "",
      termsConditions: false,
    },
  });

  const {
    mutate: startSignUp,
    isLoading: isSignUpLoading,
    isError: isSignUpError,
    isSuccess: isSignUpSuccess,
    reset: resetSignUp,
  } = useSignUp();

  const onSubmit = async (values: any) => {
    const formData = {
      nickname: values?.nickname,
      email: values?.email,
      password: values?.password,
      firstName: values?.firstName,
      lastName: values?.lastName,
      phoneNumber: values?.phoneNumber,
      phonePrefix: values?.phonePrefix?.value,
      telegramUser: values?.telegramUser,
      termsConditions: values?.termsConditions,
    };

    startSignUp(formData);
  };

  const onContinue = (data: any) => {
    if (step === 1) {
      setStep(2);
    } else {
      handleSubmit(onSubmit)();
    }
  };

  const handleSignUpSuccess = () => {
    resetSignUp();
    navigate("/login");
  };

  return (
    <div className="mb-3" style={{ placeSelf: "center" }}>
      <Heading
        title="signUp.signupTitle"
        fontSize="29px"
        fontWeight="700"
        color={theme.colors.white.normal}
        className="mb-4"
      />
      <Heading
        title={`${t("step")} ${step} ${t("of")} 2`}
        fontSize="14px"
        fontWeight="400"
        className="mb-1"
        color={theme.colors.white.dark}
      />
      <Heading
        style={{ marginBottom: "24px" }}
        title={
          step === 1
            ? t("signUp.tellUsAboutYourself")
            : t("signUp.readTermsAndConditions")
        }
        fontSize="20px"
        fontWeight="700"
        color={theme.colors.white.normal}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        {step === 1 ? (
          <StepOne 
          control={control} 
          errors={errors} 
          trigger={trigger} 
          watch={watch} 
          setValue={setValue} 
          getValues={getValues}
          clearErrors={clearErrors}
          setError={setError}
           />
        ) : (
          <StepTwo 
          control={control} 
          errors={errors} 
          />
        )}
        <Button
          title="actions.back"
          outline={true}
          type="button"
          onClick={() => (step === 2 ? setStep(1) : navigate(-1))}
          style={{
            fontSize: "14px",
            fontWeight: "700",
            borderRadius: "8px",
            minWidth: "12em",
            marginTop: "24px",
            marginRight: "24px",
          }}
        />
        <Button
          title={step === 2 ? "signIn.signUpButton" : "payments.continue"}
          style={{
            fontSize: "14px",
            fontWeight: "700",
            borderRadius: "8px",
            minWidth: "12em",
            marginTop: "16px",
          }}
          onClick={handleSubmit(onContinue)}
        />
      </form>

      <AlertModal
        open={isSignUpError}
        type="error"
        title={"signUp.somethingWrong"}
        description={"signUp.somethingWrongDescription"}
        onActionClick={resetSignUp}
      />

      <AlertModal
        open={isSignUpSuccess}
        type="success"
        title={"signUp.success"}
        description={"signUp.successDescription"}
        onActionClick={handleSignUpSuccess}
      />
      {isSignUpLoading && <FullScreenLoader />}
    </div>
  );
};

export default StepperWeb;
