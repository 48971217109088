import { useGetProfiles } from "@api/useGetProfiles";
import Heading from "@components/font/heading";
import { TabContext } from "@context/TabContext";
import { Icon } from "@iconify/react";
import { theme } from "@styles/themes";
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import backBtn from "@assets/icons/arrowLeft.svg";
import { useFilter } from "@context/FilterContext";

const ProfileBookieHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location?.pathname;

  const { setFilterOpen } = useFilter();

  const handleCross = () => {
    navigate(-1);
    setFilterOpen(false);
  };
  const { data } = useGetProfiles({});
  const { profileIdSelected } = useContext(TabContext);
  const profileHeadName =
    data?.profiles?.find((elem) => elem.profileId === profileIdSelected)
      ?.profileName || "";

  const bookiesLength = data?.profiles?.find(
    (elem) => elem.profileId === profileIdSelected
  )?.bookies.length;
  return (
    <div
      className="d-flex align-items-center justify-content-between"
      style={{
        borderBottom: "0.5px solid",
        borderBottomColor: theme.colors.white.dark,
        padding: "14px  20px 14px 20px",
        height: "55px",
        position: "fixed",
        top: 0,
        zIndex: 2,
        backgroundColor:"rgb(26, 26, 26)",
        width:"100%",
      }}
    >
      <div className="d-flex align-items-center">
        <img
          src={backBtn}
          width="24px"
          height="24px"
          className="me-3"
          onClick={() => pathname === "/profile/bookie" ? navigate('/profile') : navigate(-1)}
          style={{ cursor: "pointer", color: theme.colors.white.normal, display: pathname === "/profile/bookie/record" && "none" }}
          alt="logo profile"
        />
        <Heading
          fontSize="20px"
          title={
            pathname === "/profile/bookie/add/new"
              ? "profile.addBookie"
              : pathname === "/profile/bookie/add/edit"
              ? "profile.editBookie"
              : pathname === "/profile/bookie/record"
              ? "profile.bookieRecord"
              : pathname === "/profile/bookie/archive"
              ? "profile.archieveBookie"
              : `${profileHeadName}`
          }
          fontWeight="700"
          color={theme.colors.white.normal}
        />
      </div>
      {pathname === "/profile/bookie" && (
        <div className="d-flex align-items-center">
          <Icon
            onClick={() => navigate("/profile/bookie/add/new")}
            icon="f7:plus-app"
            width="24"
            height="24"
            color={theme.colors.white.normal}
          />
          {bookiesLength > 0 && (
            <Icon
              onClick={() => navigate("/profile/bookie/archive")}
              icon="fluent:archive-16-regular"
              width="24"
              height="24"
              style={{ marginLeft: "16px" }}
              color={theme.colors.white.normal}
            />
          )}
        </div>
      )}
      <div 
      style={{
      gap:"16px", 
      alignItems: "center", 
      display: pathname === "/profile/bookie/record" ? "flex" : "none",
      }}>
      {/* {(pathname === "/profile/bookie/record") && (
        <Icon
        onClick={()=> setFilterOpen(true)}
        style={{ color: theme.colors.white.normal }}
        icon="charm:filter"
        width="24"
        height="24"
        />
      )} */}
      {(pathname === "/profile/bookie/record" ||
        pathname === "/profile/bookie/archive") && (
        <div className="d-flex align-items-center">
          <Icon
            onClick={handleCross}
            icon="bitcoin-icons:cross-filled"
            width="28"
            height="28"
            color={theme.colors.white.normal}
          />
        </div>
      )}
      </div>
    </div>
  );
};

export default ProfileBookieHeader;
