import { TextField } from "@components/form/textField";
import { theme } from "@styles/themes";
import { regexPatterns } from "@utils/regex";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
const StepThree = ({ control, errors, watch,trigger }: any) => {
  const { t } = useTranslation();

  return (
    <div>
      <Row className="g-4">
        <Col md={12} sm={12} xs={12}>
          <TextField
            floatingLabel
            control={control}
            name="password"
            bgColor={theme.colors.grey.dark}
            size="sm"
            onBlur={(e) => {
              trigger("password");
            }}
            type="password"
            placeholder={t("signUp.password")}
            errors={errors.password}
            rules={{
              required: t("errors.required"),
              minLength: {
                value: 6,
                message: t("errors.passwordPattern"),
              },
              pattern: {
                value: regexPatterns?.regPassword,
                message: t("errors.passwordPattern"),
              },
            }}
          />
        </Col>
        <Col md={12} sm={12} xs={12}>
          <TextField
           floatingLabel
            control={control}
            name="confirmPassword"
            bgColor={theme.colors.grey.dark}
            size="sm"
            onBlur={(e) => {
              trigger("confirmPassword");
            }}
            type="password"
            placeholder={t("signUp.confirmPassword")}
            errors={errors.confirmPassword}
            rules={{
              required: t("errors.required"),
              validate: (value: any) =>
                value === watch("password") || t("errors.passwordNotMatch"),
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default StepThree;
