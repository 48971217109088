import React from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import { theme } from "@styles/themes";
import { useTranslation } from "react-i18next";
import { terms } from '@utils/termsAndConditions';

const ScrollableContainer = styled.div`
  height: 50vh;
  max-width: 750px;
  overflow-y: auto;
  margin: 24px 0px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

const Checkbox = styled.input.attrs({ type: "checkbox" })`
  appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid white;
  position: relative;
  cursor: pointer;

  &:checked {
    background-color: ${theme.colors.main.normal}; /* Custom check color */
    border: 1px solid black;
  }

  &:checked::after {
    content: "";
    position: absolute;
    top: 1px;
    left: 4px;
    width: 5px;
    height: 9px;
    border: solid black;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

const Label = styled.label`
  margin-left: 8px;
  margin-bottom: 2px;
  font-size: 14px;
  cursor: pointer;
  color: ${theme.colors.white.normal};
`;

const ErrorText = styled.span`
  color: ${theme.colors.error};
  font-size: 12px;
  margin-top: 4px;
  display: block;
`;

const Body = styled.p`
font-family: ${theme.typography.fontFamily.primary};
color: ${theme.colors.white.normal};
font-size: 14px;
font-weight: 400;
line-height: 19.07px;
text-align: left;
white-space: pre-line;
box-sizing: border-box; 
padding-right: 35px;
`;

const StepTwo = ({ control, errors }: any) => {
  const { t } = useTranslation();

  return (
    <div>
      <ScrollableContainer>
      <Body>
          {terms}
        </Body>
      </ScrollableContainer>

      <CheckboxWrapper>
        <Controller
          name="termsConditions"
          control={control}
          rules={{ required: t("errors.termsConditions") }}
          render={({ field }) => (
            <>
              <Checkbox
                {...field}
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
              />
              <Label onClick={() => field.onChange(!field.value)}>
                {t("signUp.acceptTermsConditions")}
              </Label>
            </>
          )}
        />
      </CheckboxWrapper>

      {errors.termsConditions && (
        <ErrorText>{errors.termsConditions.message}</ErrorText>
      )}
    </div>
  );
};

export default StepTwo;