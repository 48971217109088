import Heading from "@components/font/heading";
import { theme } from "@styles/themes";
import React, {
  useContext,
  useState,
  useMemo,
  useCallback,
} from "react";
import { Col, Row } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import {
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
  Type,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";
import { useGetProfiles } from "@api/useGetProfiles";
import { TabContext } from "@context/TabContext";
import SkeletonLoader from "@components/loader/skeleton";
import { useAuth } from "@context/AuthContext";
import flags from "@assets/flags";
import ProfileEmpty from "@assets/profileEmpty.svg";
import { Button } from "@components/button/button";
import Badge from "@components/Badge";
import { ReactComponent as Edit } from "@assets/icons/edit.svg";
import { ReactComponent as Achive } from "@assets/icons/archive.svg";

const EmptyState = React.memo(({ setAddProfileOpen }: any) => (
  <div
    className="d-flex flex-column align-items-center justify-content-center"
    style={{ height: "82vh" }}
  >
    <img
      src={ProfileEmpty}
      style={{ maxWidth: "182px", maxHeight: "182px" }}
      alt="Empty Profile"
    />
    <Heading
      title="profile.welcomeInbetia"
      fontSize="24px"
      fontWeight="700"
      style={{ textAlign: "center" }}
      color={theme.colors.white.normal}
    />
    <Heading
      title="profile.addingBookieProfile"
      fontSize="17px"
      fontWeight="400"
      style={{ margin: "12px 0 24px 0", textAlign: "center" }}
      color={theme.colors.white.normal}
    />
    <Button
      title="profile.addProfile"
      onClick={() => setAddProfileOpen(true)}
      style={{
        fontSize: "17px",
        padding: "8px 16px",
        fontWeight: "700",
        borderRadius: "8px",
      }}
    />
  </div>
));
const LeftActions = React.memo(
  ({
    elem,
    handleArchieveDeactivateProfile,
    setEditProfileOpen,
    setProfileIdSelected,
    setProfileBookiesSelected,
    setProfileNameSelected,
  }: any) => (
    <TrailingActions>
      <SwipeAction
        destructive={false}
        onClick={() => {
          setProfileIdSelected(elem?.profileId);
          setProfileBookiesSelected(elem?.bookies);
          setProfileNameSelected(elem?.profileName);
        }}
      >
        <div className="d-flex align-items-center">
          <Edit
            style={{
              width: "24px",
              height: "24px",
              marginLeft: "24px",
              color: theme.colors.white.normal,
            }}
            onClick={() => setEditProfileOpen(true)}
          />
          <Achive
            style={{
              width: "24px",
              height: "24px",
              marginLeft: "30px",
              marginRight: "6px",
              color: theme.colors.white.normal,
            }}
            onClick={() => handleArchieveDeactivateProfile()}
          />
        </div>
      </SwipeAction>
    </TrailingActions>
  )
);

const ProfileItem = React.memo(
  ({
    elem,
    handleListClick,
    profileErrorCount,
    convertToAppCurrency,
    isActive,
    handleArchieveActivateProfile,
    handleArchieveDeactivateProfile,
    setEditProfileOpen,
    setArchieveProfileOpen,
    setProfileIdSelected,
    setProfileBookiesSelected,
    setProfileNameSelected,
  }: any) => {
    const commonProfileContent = (
      <Row className="g-0">
        <Col lg={5} md={5} sm={5} xs={5} className="d-flex align-items-center">
          <div className="d-flex align-items-center">
          <div>
            <img
              src={`${flags[elem?.country?.toLowerCase()]}`}
              width={24}
              className="me-2"
              style={!isActive ? { filter: "grayscale(100%)" } : undefined}
              alt={`${elem?.country} flag`}
            />
            </div>
            <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width:'120px'
              }}
              >
            <Heading
              title={elem.profileName}
              fontSize="14px"
              fontWeight="700"
              color={theme.colors.white.normal}
               style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            />
            </div>
            {profileErrorCount > 0 && (
              <Badge count={profileErrorCount} style={{ marginLeft: "8px" }} />
            )}
          </div>
        </Col>
        <Col lg={4} md={4} sm={4} xs={4} style={{ textAlign: "right" }}>
          <Heading
            title={convertToAppCurrency(elem.profits || 0)}
            fontSize="14px"
            fontWeight="700"
            className="me-1"
            color={theme.colors.white.normal}
          />
        </Col>
        <Col lg={3} md={3} sm={3} xs={3} style={{ textAlign: "right" }}>
          <Icon
            style={{
              fontSize: "24px",
              color: theme.colors.white.normal,
            }}
            icon={isActive ? "iconamoon:arrow-right-2" : "mage:reload"}
            onClick={
              !isActive
                ? () => handleArchieveActivateProfile(elem?.profileId)
                : undefined
            }
          />
        </Col>
      </Row>
    );

    if (!isActive) {
      return (
        <div
          style={{
            margin: "6px 0",
            borderRadius: "8px",
            padding: "16px 10px",
            width: "100%",
            backgroundColor: theme.colors.grey.normal,
          }}
        >
          {commonProfileContent}
        </div>
      );
    }

    return (
      <SwipeableList fullSwipe={false} type={Type.IOS}>
        <SwipeableListItem
          trailingActions={
            <LeftActions
              elem={elem}
              handleArchieveDeactivateProfile={handleArchieveDeactivateProfile}
              setEditProfileOpen={setEditProfileOpen}
              setArchieveProfileOpen={setArchieveProfileOpen}
              setProfileIdSelected={setProfileIdSelected}
              setProfileBookiesSelected={setProfileBookiesSelected}
              setProfileNameSelected={setProfileNameSelected}
            />
          }
        >
          <div
            onClick={() => handleListClick(elem?.profileId, elem?.country, elem?.bookies)}
            style={{
              margin: "6px 0",
              borderRadius: "8px",
              padding: "16px 10px",
              width: "100%",
              backgroundColor: theme.colors.grey.dark,
            }}
          >
            {commonProfileContent}
          </div>
        </SwipeableListItem>
      </SwipeableList>
    );
  }
);

const MobileProfileCard = ({
  handleArchieveDeactivateProfile,
  handleArchieveActivateProfile,
  setAddProfileOpen,
  setEditProfileOpen,
  setArchieveProfileOpen,
}) => {
  const { convertToAppCurrency } = useAuth();
  const navigate = useNavigate();
  const { data, isLoading } = useGetProfiles({});
  const { setProfileIdSelected, setProfileCountry, setProfileBookiesSelected, setProfileNameSelected } = useContext(TabContext);
  const [isSwipeOpen, setIsSwipeOpen] = useState<any>({ swipeDis: "", id: "" });

  const handleListClick = useCallback(
    (profileId: string, country: string, bookies: Array<string>, name: string) => {
      setProfileIdSelected(profileId);
      setProfileCountry(country);
      setProfileBookiesSelected(bookies);
      setProfileNameSelected(name)
      navigate("/profile/bookie");
    },
    [navigate, setProfileIdSelected, setProfileCountry, setProfileBookiesSelected, setProfileNameSelected]
  );

  const handleSwipeEnd = useCallback((e, swipeAmount) => {
    setIsSwipeOpen({ swipeDis: swipeAmount, id: e });
  }, []);

  const headerContent = useMemo(
    () => (
      <>
        <Col lg={5} md={5} sm={5} xs={5}>
          <Heading
            title="profile.profile"
            fontSize="14px"
            fontWeight="400"
            className="mb-2"
            style={{ marginLeft: "12px" }}
            color={theme.colors.white.dark}
          />
        </Col>
        <Col lg={4} md={4} sm={4} xs={4} style={{ textAlign: "right" }}>
          <Heading
            title="profile.profit"
            fontSize="14px"
            fontWeight="400"
            color={theme.colors.white.dark}
          />
        </Col>
        <Col lg={3} md={3} sm={3} xs={3} style={{ textAlign: "right" }}>
          <Icon
            onClick={() => setAddProfileOpen(true)}
            style={{
              fontSize: "24px",
              marginRight: "12px",
              color: theme.colors.white.normal,
            }}
            icon="heroicons-outline:user-add"
          />
        </Col>
      </>
    ),
    [setAddProfileOpen]
  );

  return (
    <Row className="mt-3" style={{ marginBottom: "86px", padding: '0px 16px' }}>
      {headerContent}
      {isLoading ? (
        <Col lg={12} md={12} sm={12} xs={12}>
          <SkeletonLoader />
        </Col>
      ) : !data?.profiles?.length ? (
        <EmptyState setAddProfileOpen={setAddProfileOpen} />
      ) : (
        data?.profiles?.map((elem, index) => {
          const profileErrorCount = (
            elem?.bookies?.filter((item) => item?.error) || []
          ).length;

          return (
            <Col lg={12} md={12} sm={12} xs={12} key={index}>
              <ProfileItem
                elem={elem}
                handleListClick={handleListClick}
                profileErrorCount={profileErrorCount}
                convertToAppCurrency={convertToAppCurrency}
                isActive={elem?.isActive}
                handleArchieveActivateProfile={handleArchieveActivateProfile}
                isSwipeOpen={isSwipeOpen}
                handleSwipeEnd={handleSwipeEnd}
                handleArchieveDeactivateProfile={
                  handleArchieveDeactivateProfile
                }
                setEditProfileOpen={setEditProfileOpen}
                setArchieveProfileOpen={setArchieveProfileOpen}
                setProfileIdSelected={setProfileIdSelected}
                setProfileBookiesSelected={setProfileBookiesSelected}
                setProfileNameSelected={setProfileNameSelected}
              />
            </Col>
          );
        })
      )}
    </Row>
  );
};

export default React.memo(MobileProfileCard);
