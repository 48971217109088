import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import './modal.css'
import styled from "styled-components";
import { theme } from "@styles/themes";
import { Button } from "@components/button/button";

const StyledButton = styled(Button)`
  font-size: 14px;
  font-weight: 700;
  height: auto;
  @media (max-width: 768px) {
      width: 100%;
  }
`;

const Heading = styled.h2`
font-size: 20px;
color: ${theme.colors.white.normal};
font-family: ${theme.typography.fontFamily.primary};
font-weight: 700;
margin: 0px;
`;

const Body = styled.p`
font-family: ${theme.typography.fontFamily.primary};
font-size: 14px;
font-weight: 400;
line-height: 19.07px;
text-align: left;
white-space: pre-line; 
`;

const Modal = ({ show, handleClose, actionBtnTitle, closeBtnTitle, title, body, onActionClick}) => {
  return (
    show &&
    <div className="custom-backdrop">
    <div className="custom-modal">
      <Container fluid className="h-100">
        <Row className="custom-header">
          <Col className="custom-col">
          <Heading>
              {title}
          </Heading>
          </Col>
          <Col xs="auto" className="custom-col-auto">
            <button className="btn-close" onClick={handleClose}></button>
          </Col>
        </Row>

        <Row className="custom-body">
          <Col className="custom-col">
            <Body>
            {body}
            </Body>
          </Col>
        </Row>

        <Row className="custom-footer" justify-content-end>
        <div className="custom-footer-actions">
          <StyledButton
              outline
              onClick={handleClose}
              title={closeBtnTitle}
              className="me-2"
            />
          <StyledButton
            onClick={onActionClick}
            title={actionBtnTitle}
          />
          </div>
        </Row>
      </Container>
    </div>
  </div>
  );
};

export default Modal;
