import React, { useState } from "react";
import GraphDashboard from "./graphDashboard";
import GetCreditsCard from "./getCredits";
import ProfitTab from "./profitTab";
import ActiveBookiesProfile from "./activeBookiesProfile";
import { DashBoardDataResponse, useGetDashboard } from "@api/useGetDashboard";
import { Loading } from "@components/loader/Loading";
import { useAuth } from "@context/AuthContext";

const DashboardSection = () => {
  const { data } = useGetDashboard() as { data: DashBoardDataResponse };
  const [activeTab, setActiveTab] = useState(0);
  const { convertToAppCurrency } = useAuth();

  if (!data) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "70vh" }}
      >
        <Loading />
      </div>
    );
  }
  // GRAPH DATQA FILTERED
  const filteredData =
    activeTab === 0
      ? data?.profitsData.all
      : activeTab === 1
      ? data?.profitsData.oneDay
      : activeTab === 2
      ? data?.profitsData?.oneWeek
      : data?.profitsData?.oneMonth;

  // FILTER PROFIT
  const filteredProfit =
    activeTab === 0
      ? data?.profits.all
      : activeTab === 1
      ? data?.profits.oneDay
      : activeTab === 2
      ? data?.profits?.oneWeek
      : data?.profits?.oneMonth;

  // FILTER PROFIT DIFF
  const filteredProfitDif =
    activeTab === 0
      ? null
      : activeTab === 1
      ? data?.profitsDif.oneDay
      : activeTab === 2
      ? data?.profitsDif?.oneWeek
      : data?.profitsDif?.oneMonth;

  return (
    <div style={{padding:'0px 16px'}}>
      <GetCreditsCard />
      <ProfitTab
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        filteredProfit={convertToAppCurrency(filteredProfit)}
        filteredProfitDif={filteredProfitDif}
      />
      <GraphDashboard activeTab={activeTab} data={filteredData} />
      <ActiveBookiesProfile data={data} />
    </div>
  );
};

export default DashboardSection;
