import React, { createContext, useContext, useState } from "react";

interface CalendarContextType {
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  resetData: () => void;
}

const CalendarContext = createContext<CalendarContextType | undefined>(undefined);

export const CalendarProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const resetData = () => {
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <CalendarContext.Provider value={{ startDate, setStartDate, endDate, setEndDate, resetData }}>
      {children}
    </CalendarContext.Provider>
  );
};

export const useCalendar = () => {
  const context = useContext(CalendarContext);
  if (context === undefined) {
    throw new Error("calendar must be used within a CalendarProvider");
  }
  return context;
};