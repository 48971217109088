import { theme } from "@styles/themes";
import React from "react";
import styled from "styled-components";
import DashboardEmpty from "@assets/dashboardEmpty.svg";
import { useTranslation } from "react-i18next";

const Typography = styled.p`
  margin: 0;
  font-size: 16px;
  font-family: ${theme.typography.fontFamily.primary};
  color: ${theme.colors.white.normal};
  line-height: 22.4px;
  font-weight: 400;
`;

const EmptyView = () => {
  const { t } = useTranslation();
  return (
    <div
    className="d-flex flex-column align-items-center justify-content-center"
    style={{ height: "240px" }}
  >
    <img
      src={DashboardEmpty}
      style={{ maxWidth: "150px", maxHeight: "172px" }}
      alt='logo empty view'
    />  
    <Typography>
        {t("dashboard.emptyView")}
    </Typography>
    </div>
  );
};

export default EmptyView;
