import React from "react";
import {
  Controller,
  Control,
  FieldValues,
  FieldError,
  Path,
  PathValue,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { theme } from "@styles/themes";

const CheckboxWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
`;

const Label = styled.label<{ disabled?: boolean; }>`
  font-size: 14px;
  cursor: pointer;
  line-height: 19px;
  font-weight: 400;
  font-family: ${theme.typography.fontFamily.primary};
  color: ${(props) =>
    props.disabled ? theme.colors.white.darkActive : theme.colors.white.normal};
  span {
      text-decoration: underline;
      cursor: pointer;
  }
`;

const Span = styled.span`
  margin-left: 4px;
`;

const CheckboxIcon = styled.input.attrs<{ error?: boolean }>({
  type: "checkbox",
})`
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid white;
  position: relative;
  cursor: pointer;

  &:checked {
    background-color: ${theme.colors.main.normal};
    border: 1px solid black;
  }

  &:checked::after {
    content: "";
    position: absolute;
    top: 1px;
    left: 4px;
    width: 5px;
    height: 9px;
    border: solid black;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  ${(props) => props.error && `border-color: ${theme.colors.red.normal};`}
    &:disabled {
    cursor: not-allowed;
    border-color: ${theme.colors.white.darkActive}; /* Disabled border color */
    opacity: 0.6; /* Optional: Make it look grayed out */
  }

`;

const ErrorMessage = styled.div`
  color: ${theme.colors.red.normal};
  font-size: 12px;
  margin-top: 2px;
`;


const ContainerLabel = styled.div`
  display: flex;
`;

const ContainerCheckbox = styled.div`
  width: 20px;
  height: 20px; 
  display: flex; 
  align-items: center; 
  justifyContent:center;
`;

interface CheckboxFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  label: string;
  rules?: any;
  defaultValue?: PathValue<T, Path<T>>;
  errors?: FieldError;
  disabled?: boolean;
  style?: React.CSSProperties;
  className?: string;
  watch?: any;
  onLabelClick?: () => void;
  labelextend?: string;
}

export const CheckboxField = <T extends FieldValues>({
  control,
  name,
  label,
  rules,
  defaultValue = false as any,
  errors,
  disabled,
  style,
  className,
  watch,
  onLabelClick,
  labelextend
}: CheckboxFieldProps<T>) => {
  const { t } = useTranslation();
  const checkboxId = `checkbox-${label.replace(/\s+/g, "-").toLowerCase()}`;

  const handleLabelClick = (e: React.MouseEvent) => {
      e.preventDefault();
      onLabelClick();
  };

  return (
    <div style={{ width: "100%", ...style }} className={className}>
      <CheckboxWrapper>
        <Controller
          name={name}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          render={({
            field: { onChange: handleChange, value: fieldValue },
          }) => (
            <>
            <ContainerCheckbox>
              <CheckboxIcon
                id={checkboxId}
                type="checkbox"
                checked={fieldValue}
                onChange={(e) => handleChange(e.target.checked)}
                error={!!errors}
                disabled={disabled}
              />
            </ContainerCheckbox>
              {
                onLabelClick ? (
                  <ContainerLabel>
                    <Label 
                    htmlFor={checkboxId} 
                    disabled={disabled} 
                    >
                    {t(label)}
                    <Span onClick={handleLabelClick}>{t(labelextend)}</Span>
                    </Label>
                  </ContainerLabel>
                ): (
                  <Label 
                  htmlFor={checkboxId} 
                  disabled={disabled}>
                  {t(label)}
                  </Label>
                )
              }
            </>
          )}
        />
      </CheckboxWrapper>

      {errors && (
        <ErrorMessage>{t(errors.message) || t("errors.required")}</ErrorMessage>
      )}
    </div>
  );
};
