import React from "react";
import { useTranslation } from "react-i18next";
import { CheckboxField } from "@components/CheckboxField";
import { Col } from "react-bootstrap";

const AcceptTaCPayment = ({ 
  setShowModal, 
  control, 
  watch,
  watchIsAcceptedTerms
}) => {
  const { t } = useTranslation();

  return (
    <Col md={12}>
      <CheckboxField
        name="isAcceptedTerms"
        label={t("payments.acceptedTerms")}
        defaultValue={watchIsAcceptedTerms}
        control={control}
        watch={watch}
        onLabelClick={()=> setShowModal(true)}
        labelextend={t("payments.termsAndConditionsExtend")}
      />
    </Col>
  );
};

export default AcceptTaCPayment;
