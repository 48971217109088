import Heading from "@components/font/heading";
import { Icon } from "@iconify/react";
import { theme } from "@styles/themes";
import React from "react";
import { useNavigate } from "react-router-dom";

const NotificationHeader = () => {
  const navigate = useNavigate();

  return (
    <div
      className="d-flex align-items-center justify-content-between"
      style={{
        borderBottom: "0.5px solid",
        borderBottomColor: theme.colors.white.dark,
        padding: "14px  20px 14px 20px",
        height:"55px",
        position: "fixed",
        top: 0,
        zIndex: 2,
        backgroundColor:"rgb(26, 26, 26)",
        width:"100%",
      }}
    >
      <Heading
        fontSize="20px"
        title="profile.notifications"
        fontWeight="700"
        color={theme.colors.white.normal}
      />
      <Icon
        onClick={() => navigate(-1)}
        icon="charm:cross"
        width="24"
        height="24"
        color={theme.colors.white.normal}
      />
    </div>
  );
};

export default NotificationHeader;
