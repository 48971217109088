import Heading from "@components/font/heading";
import { theme } from "@styles/themes";
import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FilterType, useStatsChart } from "@context/StatsChartContext";
import { Control } from "react-hook-form";
import soccer from "@assets/sportsIcon/football.svg";
import volleyball from "@assets/sportsIcon/ball-volleyball.svg";
import bowling from "@assets/sportsIcon/ball-bowling.svg";
import tennis from "@assets/sportsIcon/ball-tennis.svg";
import basketball from "@assets/sportsIcon/ball-basketball.svg";
import baseball from "@assets/sportsIcon/ball-baseball.svg";
import americanfootball from "@assets/sportsIcon/ball-american-football.svg";
import esports from "@assets/sportsIcon/esports.svg";
import hockey from "@assets/sportsIcon/hockey.svg";
import tableTennis from "@assets/sportsIcon/table-tennis.svg";
import handball from "@assets/sportsIcon/handball.svg";
import cycling from "@assets/sportsIcon/cycling.svg";
import casino from "@assets/sportsIcon/casino.svg";
import dart from "@assets/sportsIcon/dart.svg";
import boxing from "@assets/sportsIcon/boxing.svg";
import horseRace from "@assets/sportsIcon/horse.svg";
import greyHound from "@assets/sportsIcon/greyhound.svg";
import others from "@assets/sportsIcon/others.svg";
import DateRangePicker from "@components/calendar";

const sportIcons = {
  soccer,
  volleyball,
  bowling,
  tennis,
  basketball,
  baseball,
  americanfootball,
  esports,
  hockey,
  tableTennis,
  handball,
  cycling,
  casino,
  dart,
  boxing,
  horseRace,
  greyHound,
  others,
};

export const Checkbox = styled.input.attrs({ type: "checkbox" })`
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid white;
  position: relative;
  cursor: pointer;
  margin-right: 8px;

  &:checked {
    background-color: ${theme.colors.main.normal}; /* Custom check color */
    border: 1px solid black;
  }

  &:checked::after {
    content: "";
    position: absolute;
    top: 1px;
    left: 5px;
    width: 5px;
    height: 10px;
    border: solid black;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

interface FilterSectionProps {
  control: Control<any>;
  filterType: FilterType;
}

const FilterSection: React.FC<FilterSectionProps> = ({
  control,
  filterType,
}) => {
  const { t } = useTranslation();
  const { statsData, tempFilters, setTempFilter, predefinedStates } = useStatsChart();

  const [searchTerm, setSearchTerm] = useState("");
  const [showAllSports, setShowAllSports] = useState(false);
  const [visibleStatesCount, setVisibleStatesCount] = useState(5);

  const { sports } = useMemo(() => {
    if (!statsData) return { sports: [], states: [] };

    const sportsSet = new Set<string>();
    Object.entries(statsData.stats).forEach(([_, profiles]) => {
      profiles.forEach((profile) => {
        Object.keys(profile.sports).forEach((sport) => {
          sportsSet.add(sport);
        });
      });
    });

    return { sports: Array.from(sportsSet), states: predefinedStates};
  }, [statsData]);

  const handleStateToggle = (state: string) => {
    setTempFilter(
      filterType,
      "states",
      tempFilters[filterType].states.includes(state)
        ? tempFilters[filterType].states.filter((s) => s !== state)
        : [...tempFilters[filterType].states, state]
    );
  };

  const handleSportToggle = (sport: string) => {
    setTempFilter(
      filterType,
      "sports",
      tempFilters[filterType].sports.includes(sport)
        ? tempFilters[filterType].sports.filter((s) => s !== sport)
        : [...tempFilters[filterType].sports, sport]
    );
  };

  const toggleAllStates = () => {
    setTempFilter(
      filterType,
      "states",
      tempFilters[filterType].states.length === predefinedStates.length ? [] : predefinedStates
    );
  };

  const toggleAllSports = () => {
    setTempFilter(
      filterType,
      "sports",
      tempFilters[filterType].sports.length === sports.length ? [] : sports
    );
  };

  return (
    <div style={{ padding: "16px" }}>
      <div
        style={{
          borderBottom: `1px solid ${theme.colors.grey.dark}`,
          paddingBottom: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Heading
          title="calendar.date"
          fontSize="14px"
          fontWeight="700"
          color={theme.colors.white.normal}
        />
        <DateRangePicker />
      </div>

      <div
        style={{
          borderBottom: `1px solid ${theme.colors.grey.dark}`,
          paddingBottom: "16px",
          marginTop: "12px",
        }}
      >
        <Heading
          title={t('historial.states.states')}
          fontSize="14px"
          fontWeight="700"
          color={theme.colors.white.normal}
        />

        <div className="d-flex align-items-center mb-3 mt-3">
          <Checkbox
            checked={tempFilters[filterType].states.length === predefinedStates.length}
            onChange={toggleAllStates}
          />
          <Heading
            title={t("profile.selectAll")}
            fontSize="14px"
            fontWeight="400"
            color={theme.colors.white.normal}
          />
        </div>

        {predefinedStates
          .filter((state) =>
            state.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .slice(0, visibleStatesCount)
          .map((state) => (
            <div className="d-flex align-items-center mb-2" key={state}>
              <Checkbox
                checked={tempFilters[filterType].states.includes(state)}
                onChange={() => handleStateToggle(state)}
              />
              <Heading
                title={t(`historial.states.${state}`, state)}
                fontSize="14px"
                fontWeight="400"
                color={theme.colors.white.normal}
              />
            </div>
          ))}

        <div className="d-flex align-items-center" style={{ gap: "16px" }}>
          {predefinedStates.length > visibleStatesCount && (
            <Heading
              title={t("actions.seeMore")}
              fontSize="14px"
              fontWeight="400"
              style={{ textDecoration: "underline", cursor: "pointer" }}
              color={theme.colors.white.normal}
              onClick={() => setVisibleStatesCount(predefinedStates.length)}
            />
          )}

          {visibleStatesCount > 5 && (
            <Heading
              title={t("actions.hide")}
              fontSize="14px"
              fontWeight="400"
              style={{ textDecoration: "underline", cursor: "pointer" }}
              color={theme.colors.white.normal}
              onClick={() => setVisibleStatesCount(5)}
            />
          )}
        </div>
      </div>

      <div>
        <div style={{ marginTop: "12px" }}>
          <Heading
            title="Sports"
            fontSize="14px"
            fontWeight="700"
            color={theme.colors.white.normal}
          />

          <div className="d-flex align-items-center mb-3 mt-3">
            <Checkbox
              checked={tempFilters[filterType].sports.length === sports.length}
              onChange={toggleAllSports}
            />
            <Heading
              title={t("profile.selectAll")}
              fontSize="14px"
              fontWeight="400"
              color={theme.colors.white.normal}
            />
          </div>

          {sports.slice(0, showAllSports ? undefined : 5).map((sport) => (
            <div
              style={{
                display: "flex",
                height: "24px",
                alignItems: "center",
                marginBottom: "8px",
              }}
              key={sport}
            >
              <Checkbox
                checked={tempFilters[filterType].sports.includes(sport)}
                onChange={() => handleSportToggle(sport)}
              />
              <img
                src={sportIcons[sport.toLowerCase()] || sportIcons.others}
                alt={`${sport} logo`}
                style={{
                  width: "16px",
                  height: "16px",
                  marginRight: "4px",
                }}
              />
              <Heading
                title={t(`dashboard.sports.${sport}`, sport)} 
                fontSize="14px"
                fontWeight="400"
                color={theme.colors.white.normal}
                style={{ textTransform: "capitalize" }}
              />
            </div>
          ))}

          {sports.length > 5 && (
            <div className="d-flex align-items-center">
              <Heading
                title={showAllSports ? t("actions.hide") : t("actions.seeMore")}
                fontSize="14px"
                fontWeight="400"
                style={{ textDecoration: "underline", cursor: "pointer" }}
                color={theme.colors.white.normal}
                onClick={() => setShowAllSports(!showAllSports)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FilterSection;
