import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";

import CountryCodePicker from "@components/form/phoneInput";
import { TextField } from "@components/form/textField";
import { theme } from "@styles/themes";
import {
  CheckboxWrapper,
  Label,
} from "../../signUp/steps/mobile/StepFive";
import { Button } from "@components/button/button";
import { countryCodes } from "@utils/countries";
import { useEditMyAccount } from "@api/useEditMyAccount";
import { useAuth } from "@context/AuthContext";
import { regexPatterns } from "@utils/regex";

export const Checkbox = styled.input.attrs({ type: "checkbox" })`
  appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid white;
  position: relative;
  cursor: pointer;

  &:checked {
    background-color: ${theme.colors.main.normal}; /* Custom check color */
    border: 1px solid black;
  }

  &:checked::after {
    content: "";
    position: absolute;
    top: 1px;
    left: 4px;
    width: 5px;
    height: 9px;
    border: solid black;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

const StyledButton = styled(Button)`
  font-size: 14px;
  font-weight: 700;
  border-radius: 8px;
  width: 100%;
  margin-top: 16px;
  margin: 0;

  @media (max-width: 768px) {
    width: 100%; /* Full width on mobile */
  }
`;
const StyledContainer = styled.div`
  max-width: 600px;
  width: 100%; /* Ensure it takes full width in wider screens */

  @media (max-width: 768px) {
    max-width: 100%; /* Set max-width to 100% in mobile view */
  }
`;
const FormMyData = () => {
  const { setAccount, accountDetail } = useAuth();
  const { t } = useTranslation();
  const { mutate } = useEditMyAccount({
    onSuccess: (res) => {
      setAccount(res?.data?.myAccount);
    },
  });

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    trigger,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      firstName: accountDetail?.firstName,
      lastName: accountDetail?.lastName,
      email: "",
      phonePrefix: "",
      phoneNumber: accountDetail?.phoneNumber,
      nickname: accountDetail?.nickname,
      telegramUser: accountDetail?.telegramUser,
      password: "",
      confirmPassword: "",
      termsConditions: false,
    },
  });
  const onSubmit = async (values: any) => {
    const formData = {
      firstName: values?.firstName,
      lastName: values?.lastName,
      phoneNumber: values?.phoneNumber,
      phonePrefix: values?.phonePrefix?.value,
      telegramUser: values?.telegramUser,
    };
    mutate(formData);
  };

  const DefaultCountryObj = countryCodes.find(
    (country) => country.value === accountDetail?.phonePrefix
  );

  // console.log(matchedCountry,'matches');
  return (
    <StyledContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className="g-4">
          <Col md={12} sm={12} xs={12}>
            <TextField
              floatingLabel
              control={control}
              name="nickname"
              defaultValue={accountDetail?.nickname}
              bgColor={theme.colors.grey.dark}
              size="sm"
              disabled
              placeholder={t("login.nickname")}
            />
          </Col>
          <Col md={12} sm={12} xs={12}>
            <TextField
              floatingLabel
              control={control}
              name="firstName"
              onBlur={(e) => {
                trigger("firstName");
              }}
              defaultValue={accountDetail?.firstName}
              bgColor={theme.colors.grey.dark}
              size="sm"
              rules={{
                required: t("errors.required"),
                pattern: {
                  value: regexPatterns?.regOnlyChar,
                  message: t("errors.namePattern"),
                },
              }}
              placeholder={t("profile.name")}
            />
          </Col>
          <Col md={12} sm={12} xs={12}>
            <TextField
              floatingLabel
              control={control}
              name="lastName"
              onBlur={(e) => {
                trigger("lastName");
              }}
              rules={{
                required: t("errors.required"),
                pattern: {
                  value: regexPatterns?.regOnlyChar,
                  message: t("errors.namePattern"),
                },
              }}
              defaultValue={accountDetail?.lastName}
              bgColor={theme.colors.grey.dark}
              size="sm"
              placeholder={t("profile.surname")}
            />
          </Col>
          <Col md={3} sm={3} xs={5} className="d-flex align-items-start">
            <CountryCodePicker
              bgColor={theme.colors.grey.dark}
              size="sm"
              options={countryCodes}
              placeholder={t("profile.phonePrefix")}
              control={control}
              defaultValue={DefaultCountryObj}
              name="phonePrefix"
              setValue={setValue}
              errors={errors.phonePrefix}
              rules={{ required: false }}
            />
          </Col>
          <Col md={9} sm={9} xs={7} style={{ paddingLeft: "10px" }} className="d-flex align-items-end">
            <TextField
              floatingLabel
              control={control}
              name="phoneNumber"
              defaultValue={accountDetail?.phoneNumber}
              bgColor={theme.colors.grey.dark}
              size="sm"
              placeholder={t("profile.phoneNumber")}
              errors={errors.phoneNumber as any}
              rules={{ required: false }}
            />
          </Col>
          <Col md={12} sm={12} xs={12}>
            <TextField
              floatingLabel
              control={control}
              name="telegramUser"
              defaultValue={accountDetail?.telegramUser || ""} // Start with empty value for placeholder
              bgColor={theme.colors.grey.dark}
              size="sm"
              placeholder="@Telegram" // Placeholder remains visible initially
              onBlur={() => {
                const value = getValues("telegramUser") || "";
                if (value && !value.startsWith("@")) {
                  setValue("telegramUser", `@${value}`); // Prepend "@" if missing
                }
                trigger("telegramUser");
              }}
              onChange={(e) => {
                const value = e.target.value || "";
                if (!value.startsWith("@") && value.length > 0) {
                  setValue("telegramUser", `@${value}`); // Dynamically add "@" if missing
                } else {
                  setValue("telegramUser", value); // Allow typing normally
                }
              }}
              errors={errors.telegramUser as any}
              rules={{
                required: false,
                pattern: {
                  value: regexPatterns?.regTelegram, // Validate Telegram username format
                  message: t("errors.telegramPattern"),
                },
              }}
            />
          </Col>

          <Col md={12} sm={12} xs={12}>
            <CheckboxWrapper style={{ margin: "12px 0" }}>
              <Controller
                name="termsConditions"
                control={control}
                rules={{ required: false }}
                render={({ field }: any) => (
                  <>
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                    <Label onClick={() => field.onChange(!field.value)}>
                      {t("profile.telegramNotifications")}
                    </Label>
                  </>
                )}
              />
            </CheckboxWrapper>
          </Col>
          <Col md={12} sm={12} xs={12}>
          <div style={{ whiteSpace:"nowrap" }}>
            <StyledButton
              title="profile.saveChanges"
              disabled={
                watch("firstName") ||
                watch("lastName") ||
                watch("phoneNumber") ||
                watch("phonePrefix") ||
                watch("telegramUser")
                  ? false
                  : true
              }
            />
          </div>
          </Col>
        </Row>
      </form>
    </StyledContainer>
  );
};

export default FormMyData;
