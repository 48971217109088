import React from "react";
import ForgotPasswordSection from "../../section/forgotPassword";
import { Col, Container, Row } from "react-bootstrap";
import Heading from "@components/font/heading";
import { theme } from "@styles/themes";
import Background from "@assets/Frame.svg";
import Logo from "@assets/newLogo.png";

const ForgotPassword = () => {
  return (
    <Container fluid className="p-0">
      <Row className="g-0 justify-content-center">
        <Col
          className="d-none d-md-block"
          xl={7}
          lg={7}
          md={7}
          sm={6}
          xs={0}
          style={{
            backgroundImage: `url(${Background})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top",
            minHeight: "100vh",
            position: "relative",
          }}
        >
          <div style={{ position: "absolute", top: 100, left: 100 }}>
            <img src={Logo} width={300} height="auto" />
            <Heading
              title="login.readyForNewEra"
              fontSize="24px"
              fontWeight="700"
              style={{ width: "100%", marginTop: "24px", lineHeight: "1.2em" }}
              color={theme.colors.white.normal}
            />
          </div>
        </Col>
        <Col xl={5} lg={5} md={5} sm={12} xs={12} className="">
          <ForgotPasswordSection />
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassword;
