import React from "react";
import styled from "styled-components";
import Head from "../myDataSection/head";
import BillingAddressForm from "./form";

export const StyledDiv = styled.div`
  padding: 32px 24px;

  @media (max-width: 768px) {
    padding: 16px;
    margin: 0px;
  }
`;

const BillingDetailsSection = () => {
  return (
    <StyledDiv>
      <Head title="profile.billingDetails" />
      <BillingAddressForm />
    </StyledDiv>
  );
};

export default BillingDetailsSection;
