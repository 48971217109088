export const regexPatterns = {
    regOnlyChar: /^[A-Za-zÁÉÍÓÚáéíóúÀÈÌÒÙàèìòùÄËÏÖÜäëïöüÇçÑñ]+(\s[A-Za-zÁÉÍÓÚáéíóúÀÈÌÒÙàèìòùÄËÏÖÜäëïöüÇçÑñ]+)*$/,
    regTextWithNumbers: /^[A-Za-zÁÉÍÓÚáéíóúÀÈÌÒÙàèìòùÄËÏÖÜäëïöüÇçÑñ0-9]+(\s[A-Za-zÁÉÍÓÚáéíóúÀÈÌÒÙàèìòùÄËÏÖÜäëïöüÇçÑñ0-9]+)*$/,
    regEmail: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    regOnlyNum: /^[0-9]+$/,
    regUsername: /^[a-zA-Z0-9_.-]+$/,
    regPassword: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.-])[A-Za-z\d@$!%*?&.-]{8,}$/,
    regTelegram: /^@[^\s@]+$/,
    zipCodeRegex: /^(?=[a-zA-Z0-9]*$)(?=[a-zA-Z]*\d)(?=\d*\w{1,})/,
};
