import React from "react";
import Head from "./head";
import FormMyData from "./form";
import ForgotPasswordForm from "./forgotPasswordForm";
import styled from "styled-components";

const StyledDiv = styled.div`
  padding: 32px 24px;
  @media (max-width: 768px) {
    padding: 16px;
    margin: 0px;
  }
`;

const MyDataSection = () => {
  return (
    <StyledDiv >
      <Head title='profile.myData'/>
      <FormMyData/>
      <ForgotPasswordForm/>
    </StyledDiv>
  );
};

export default MyDataSection;
