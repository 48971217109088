import useWidth from "@hooks/useWidth";
import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from "recharts";
import { GraphData } from "@api/useGetDashboard";
import styled from "styled-components";
import EmptyView from "./EmptyView";
import { useAuth } from "@context/AuthContext";

const TooltipContainer = styled.div`
  padding: 8px;
  border-radius: 4px;
  background-color: #383838;
`;

const DateText = styled.p`
  margin: 0;
  font-size: 14px;
  color: #ffffff;
`;

const ValueText = styled.p<{ isNegative: boolean }>`
  margin: 0;
  font-size: 14px;
  color: ${(props) => (props.isNegative ? "#FF0000" : "#04D9BC")};
`;

const GraphDashboard = ({ data, activeTab }: { data: GraphData; activeTab: number }) => {
  const width = useWidth();
  const { convertToAppCurrency } = useAuth();

  const dataDemo = data?.xAxis.map((timestamp, index) => ({
    date: new Date(timestamp),
    value: data?.yAxis[index],
  }));

  const CustomTooltip = ({
    active,
    payload,
  }: {
    active?: boolean;
    payload?: any[];
  }) => {
    if (active && payload && payload.length) {
      const {
        value,
        payload: { date },
      } = payload[0];
      const dataDate = new Date(date);
      const dataTime = dataDate.getTime();
  
      if (isNaN(dataTime)) return null;
  
      const formattedDate = formatDateByTab(dataDate, activeTab);
      const formattedValue = convertToAppCurrency(value);
      return (
        <TooltipContainer>
          <DateText>{formattedDate}</DateText>
          <ValueText isNegative={value < 0}>{`${formattedValue}`}</ValueText>
        </TooltipContainer>
      );
    }
    return null;
  };
  
  const formatDateByTab = (date: Date, activeTab: number): string => {
    switch (activeTab) {
      case 0:
        return date.toLocaleDateString("en-US", {
          day: "numeric",
          month: "short",
          year: "numeric",
        });
      case 1:
        return date.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        });
      case 2:
        return (
          date.toLocaleDateString("en-US", {
            weekday: "short",
            day: "numeric",
            month: "short",
          }) +
          `, ${date.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          })}`
        );
      case 3:
        return date.toLocaleDateString("en-US", {
          weekday: "short",
          day: "numeric",
          month: "short",
        });
      default:
        return date.toISOString();
    }
  };

  const calculateTicks = () => {
    const interval = 4 * 60 * 60 * 1000;
    const numberOfTicks = 6;
  
    if (activeTab === 1) {
      const ticks = [];
      let lastTick = null;
  
      for (let i = 0; i < dataDemo.length; i++) {
        const currentTime = dataDemo[i].date.getTime();
        if (!lastTick || currentTime >= lastTick + interval) {
          ticks.push(currentTime);
          lastTick = currentTime;
        }
  
        if (ticks.length === numberOfTicks) {
          break;
        }
      }
  
      return ticks;
    }
  
    if (dataDemo.length < numberOfTicks) {
      return dataDemo.map((item) => item.date.getTime());
    }
  
    const ticks = [];
    const step = (dataDemo.length - 1) / (numberOfTicks - 1);
  
    for (let i = 0; i < numberOfTicks; i++) {
      const index = Math.ceil(i * step);
      ticks.push(dataDemo[index].date.getTime());
    }
  
    return ticks;
  };
  
  const ticks = calculateTicks();

  return (
    <div className="w-full h-[350px] bg-gray-900">
      {dataDemo.length ? (
              <AreaChart width={width - 40} height={240} data={dataDemo}>
              <defs>
                <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#04D9BC" stopOpacity={0.8} />
                  <stop offset="100%" stopColor="#04D9BC" stopOpacity={0.1} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="date"
                ticks={ticks} 
                interval={activeTab === 1 ? "preserveEnd" : "preserveStartEnd"}
                tickCount={6}
                tickFormatter={(time) => {
                  if (time === dataDemo[0].date) return "";
                  const date = new Date(time);
                  if (activeTab === 1) {
                    date.setMinutes(0, 0, 0);
                    return date.toLocaleTimeString("en-US", {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                    });
                  }
                  return date
                    .toLocaleDateString("en-US", {
                      day: "numeric",
                      month: "short",
                    })
                    .replace(/^\w/, (c) => c.toUpperCase());
                }}
                stroke="#FFFFFF"
                tickSize={0}
                axisLine={{ stroke: "#404040"}}
                tickMargin={14}
                tickLine={{ stroke: "#404040" }}
                tick={{ fill: "#FFFFFF", fontSize: 12}}
              />
              <YAxis
                tickFormatter={(value) => `${value}€`}
                stroke="#FFFFFF"
                axisLine={false}
                tickLine={{ stroke: "#404040" }}
                orientation={"left"}
                tick={{ fill: "#FFFFFF", fontSize: 12 }}
                tickMargin={10}
              />
              <CartesianGrid stroke="#404040" horizontal={true} vertical={false} />
              <Tooltip content={CustomTooltip} />
              <Area
                type="monotone"
                dataKey="value"
                stroke="#04D9BC"
                fill="url(#colorGradient)"
                fillOpacity={1}
              />
            </AreaChart>
      ) :(
        <EmptyView/>
      )}
    </div>
  );
};

export default GraphDashboard;
