import Heading from "@components/font/heading";
import { usePayment } from "@context/PaymentContext";
import { TabContext } from "@context/TabContext";
import { Icon } from "@iconify/react";
import { theme } from "@styles/themes";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import backBtn from "@assets/icons/arrowLeft.svg";
import { useTranslation } from "react-i18next";

const BillingDetailHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tabContext = useContext(TabContext);
  const { activeTab, setActiveTab } = tabContext;
  const { resetPayment } = usePayment();
  const handleNavigate = (tab: any) => {
    if (tab === 0) {
      navigate(-1);
    } else if (tab === 1) {
      setActiveTab(0);
    } else {
      resetPayment();
      setActiveTab(1);
    }
  };

  const handleCross = () => {
    navigate("/payments");
    setActiveTab(0);
  };

  return (
    <div>
      <div
        className="d-flex align-items-center justify-content-between"
        style={{
          borderBottom: "0.5px solid",
          borderBottomColor: theme.colors.white.dark,
          padding: "14px  20px 14px 20px",
          height: "55px",
          position: "fixed",
          top: 0,
          zIndex: 2,
          backgroundColor:"rgb(26, 26, 26)",
          width:"100%",
        }}
      >
        <div className="d-flex align-items-center">
         
          <img
          src={backBtn}
          width="24px"
          height="24px"
          className="me-3"
          onClick={() => handleNavigate(activeTab)}
          style={{ cursor: "pointer", color: theme.colors.white.normal }}
          alt="icon"
        />
          <Heading
            fontSize="20px"
            title={
              activeTab === 0
                ? t("sidebarMobile.billing")
                : activeTab === 1
                ? t("profile.paymentMethod")
                : t("payments.summary")
            }
            fontWeight="700"
            color={theme.colors.white.normal}
          />
        </div>
        <div className="d-flex align-items-center">
          <Icon
            onClick={handleCross}
            icon="charm:cross"
            width="24"
            height="24"
            color={theme.colors.white.normal}
          />
        </div>
      </div>
    </div>
  );
};

export default BillingDetailHeader;
