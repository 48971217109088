import React, { useState, useEffect } from "react";
import Arrow from "@assets/icons/chevron-up-white.svg";

interface ScrollToTopProps {
  containerRef?: React.RefObject<HTMLDivElement>;
}

const ScrollToTop: React.FC<ScrollToTopProps> = ({ containerRef }) => {
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const container = containerRef?.current || window;

    const handleScroll = () => {
      let currentScrollY = 0;

      if (container === window) {
        currentScrollY = window.scrollY;
      } else if (container instanceof HTMLElement) {
        currentScrollY = container.scrollTop;
      }

      console.log(`currentScrollY: ${currentScrollY}, lastScrollY: ${lastScrollY}`);

      if (currentScrollY < lastScrollY && currentScrollY > 200) {
        setIsScrollingUp(true);
      } else {
        setIsScrollingUp(false);
      }

      setLastScrollY(currentScrollY);
    };

    if (container === window || container instanceof HTMLElement) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container === window || container instanceof HTMLElement) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [lastScrollY, containerRef]);

  const scrollToTop = () => {
    const container = containerRef?.current || window;

    if (container === window) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else if (container instanceof HTMLElement) {
      container.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  
  return (
    isScrollingUp && (
      <button
        style={{
          position: "fixed",
          right: "16px",
          top: "72px",
          backgroundColor: "#1A1A1A",
          color: "white",
          border: "1px solid #fff",
          borderRadius: "8px",
          width: "48px",
          height: "48px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          cursor: "pointer",
        }}
        onClick={scrollToTop}
      >
          <img src={Arrow} alt="Dashed line" />
      </button>
    )
  );
};

export default ScrollToTop;
