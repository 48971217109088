// libraries
import { useMutation } from "react-query";
import { AxiosError } from "axios";

// misc
import request from "@utils/request";
import { ServerResponse } from "./types";
import { useErrorHandler } from "@hooks/useErrorHandler";
import { AddAddressRequest } from "./useAddAddress";

export interface The3DResponse {
  Ds_Amount: string;
  Ds_Currency: string;
  Ds_EMV3DS: DsEMV3DS;
  Ds_MerchantCode: string;
  Ds_Order: string;
  Ds_Terminal: string;
  Ds_TransactionType: string;
}

export interface DsEMV3DS {
  acsURL: string;
  creq: string;
  protocolVersion: string;
  threeDSInfo: string;
}

export type PaymentResponse = ServerResponse<null | The3DResponse>;

export interface PaymentRequest {
  paymentAmount: number;
  idOperation: string;
  orderNumber: string;
  currency: number;
  cardholderName: string;
  isAcceptedTerms: boolean;
  billingDetails: AddAddressRequest;
}

export const useCreditCardPayment = ({
  onError,
  onSuccess,
}: {
  onSuccess?: (res: PaymentResponse) => void;
  onError?: (res: AxiosError<ServerResponse<unknown>>) => void;
} = {}) => {
  // variables
  const { triggerError } = useErrorHandler();

  // request
  const query = useMutation<
    PaymentResponse,
    ServerResponse<unknown> | undefined,
    PaymentRequest
  >(["payPreAuth"], async (data: PaymentRequest) => {
    const browserInfo = {
      browserUserAgent: navigator.userAgent, // Browser user agent
      browserJavaEnabled: navigator.javaEnabled(), // Browser Java is enabled or not
      browserJavaScriptEnabled: true, // Assumed true because React requires JavaScript to work
      browserLanguage: navigator.language || (navigator as any)?.userLanguage, // Browser language
      browserColorDepth: window.screen.colorDepth, // Browser color depth
      browserScreenHeight: window.screen.height, // Browser screen height
      browserScreenWidth: window.screen.width, // Browser screen width
      browserTZ: -(new Date().getTimezoneOffset() / 60), // Time zone in hours
    };

    try {
      const res = await request<PaymentResponse>({
        method: "post",
        url: `/payments/authorization`,
        data: { ...data, browserInfo },
      });

      onSuccess && onSuccess(res.data);
      return res.data;
    } catch (error) {
      onError && onError(error);
      triggerError({ error });
      throw error; // important to throw the error for react-query to recognize it
    }
  });

  // return
  return query;
};
