import Heading from "@components/font/heading";
import { Icon } from "@iconify/react";
import { theme } from "@styles/themes";
import React from "react";
import styled from "styled-components";
import { useDeleteNotification } from "@api/useDeleteNotification";
import { Spinner } from "react-bootstrap";
import ShowMore from "react-show-more";
import { useTranslation } from "react-i18next";


const Card = styled.div<{ isToday: boolean }>`
  background-color: ${({ isToday }) =>
    isToday ? theme.colors.grey.darkActive : theme.colors.grey.darker};
  border-bottom: 1px solid ${theme.colors.grey.dark};
  padding: 16px;
  width: 100%;
  position: relative;

  &:hover {
    .delete-icon {
      display: block;
    }
  }
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  margin-right: 10px;
  background-color: ${theme.colors.main.normal};
  border-radius: 50%;
`;

const DeleteIcon = styled.div`
  display: none;
  cursor: pointer;
`;

interface NotificationCardProps {
  title?: string;
  date?: string;
  content?: string;
  notifyId?: string;
  isIncidence?: string;
  isRead?: boolean;
  refetch?: () => void;
}

const NotificationCardDesktop: React.FC<NotificationCardProps> = ({
  title,
  date,
  content,
  notifyId,
  isIncidence,
  refetch,
  isRead,
}) => {
  const { t } = useTranslation();  
  const { mutate: deleteMutate, isLoading } = useDeleteNotification({
    onSuccess: () => {
      refetch && refetch();
    },
  });
  
  const handleDelete = (notifyId: string) => {
    deleteMutate(notifyId);
  };
  
  const today = new Date();
  const formattedDate = today.toLocaleDateString("en-GB");
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const formattedYesterday = yesterday.toLocaleDateString("en-GB");

  return (
    <Card isToday={!isRead}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          {!isRead && <Dot />}
          <StyledTitle
            title={`${title} ${isIncidence ? "⚠️" : ""}`}
            fontSize="14px"
            fontWeight="700"
            color={theme.colors.white.normal}
          />
        </div>
        <Heading
          title={
            date === formattedDate
              ? "Today"
              : date === formattedYesterday
              ? "Yesterday"
              : date
          }
          fontSize="12px"
          fontWeight="400"
          color={theme.colors.white.dark}
        />
      </div>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <StyledHeadingSeeMore style={{fontSize:'14px'}}>
            <ShowMore
              lines={2}
              more={
                <Heading
                  title={t("actions.seeMore")}
                  fontSize="12px"
                  fontWeight="400"
                  color={theme.colors.white.dark}
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                />
              }
              less={
                <Heading
                  title="See less"
                  fontSize="12px"
                  fontWeight="400"
                  color={theme.colors.white.dark}
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                />
              }
            >
               <Heading
                  title={content}
                  fontSize="14px"
                  fontWeight="400"
                  color={theme.colors.white.dark}
                
                />
              
            </ShowMore>
          </StyledHeadingSeeMore>
          <DeleteIcon
            className="delete-icon"
            onClick={() => handleDelete(notifyId)}
          >
            {isLoading ? (
              <Spinner animation="border" variant="light" />
            ) : (
              <Icon
                width={18}
                height={18}
                style={{
                  color: theme.colors.white.normal,
                }}
                icon="mingcute:delete-2-line"
              />
            )}
          </DeleteIcon>
        </div>

        
      </div>
    </Card>
  );
};

const StyledHeading = styled(Heading)<{ isExpanded: boolean }>`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: ${({ isExpanded }) => (isExpanded ? "none" : "2")};
  line-height: 19px;
`;
const StyledHeadingSeeMore = styled.div`
  font-size: "14px";
  font-weight:  "400";
  color:  ${theme.colors.white.dark};
  margin-bottom: 0;
  word-break: break-word;
  line-height: 24px;
`;
const StyledTitle = styled(Heading)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
  line-height: 19px;
`;

export default NotificationCardDesktop;
