/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import "./paymentForm.css";
import { useTheme } from "styled-components";
import { usePayment } from "@context/PaymentContext";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";

const PaymentForm = ({ onPressContinue }) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const { token, setOrderId, setToken, setCardHolderName } = usePayment();

  const { colors } = useTheme();
  const inputStyle = `color: ${colors.white.normal};`;
  const { t } = useTranslation();
  const commonInputStyle = `padding: 10px 10px;border: 1px solid ${colors.grey.dark};border-radius: 8px;font-size: 16px;width: 100%;box-sizing: border-box;background: ${colors.grey.dark};${inputStyle}`;

  function handleMessage(event) {
    // eslint-disable-next-line no-undef
    storeIdOper(event, "token", "errorCode", merchantValidationExample);
    if (!!event?.data?.idOper && event.origin == "https://sis-t.redsys.es:25443") {
      setToken(document.querySelector("#token")?.value)
    }
  }

  function merchantValidationExample() {
    return true;
  }

  function generateOrderId(secretKey = 123456789) {
    // Get the current UTC timestamp in seconds (10 digits)
    const timestamp = Math.floor(Date.now() / 1000);

    // Generate a random 2-digit number
    const randomNum = Math.floor(Math.random() * 100);

    // Combine timestamp (10 digits) and random number (2 digits)
    let combined = BigInt(`${timestamp}${randomNum}`);

    // Encrypt the combined number using XOR with the secret key
    let encryptedId = combined ^ BigInt(secretKey);

    // Ensure the result is exactly 12 digits by adding a large offset
    const offset = BigInt(10 ** 12); // Offset ensures result is positive and 12 digits
    encryptedId = encryptedId % offset;

    return encryptedId.toString().padStart(12, '0'); // Ensure it's 12 digits
  }

  useEffect(() => {
    if (token) {
      setTimeout(() => {
        onPressContinue();
      }, 1000);
    }
  }, [token]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://sis-t.redsys.es:25443/sis/NC/sandbox/redsysV3.js";
    script.async = false;
    script.onload = () => setScriptLoaded(true); // Mark the script as loaded

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []); // Dependency array is empty to ensure this runs only once

  useEffect(() => {
    if (scriptLoaded) {
      // Now the script is loaded and you can safely access the library here
      console.log("Redsys script loaded and available:");

      window.addEventListener("message", handleMessage);
      const generatedId = generateOrderId();
      setOrderId(generatedId);


      // Ensure those functions are called only once

      // eslint-disable-next-line no-undef
      getCardInput(
        "card-number",
        `padding: 5px;border: 1px solid ${colors.grey.dark};border-radius: 8px;width: 100%;box-sizing: border-box;background: ${colors.grey.dark};`,
        t("payments.cardNumber"),
        `padding: 5px 10px;font-size: 16px;width: 100%;${inputStyle}`
      );
      // eslint-disable-next-line no-undef
      getExpirationMonthInput("expiration-month", commonInputStyle, t("payments.expiryMonth"));
      // eslint-disable-next-line no-undef
      getExpirationYearInput("expiration-year", commonInputStyle, t("payments.expiryYear"));

      // eslint-disable-next-line no-undef
      getCVVInput("cvv", commonInputStyle, "CVV");

      // eslint-disable-next-line no-undef
      getPayButton(
        "boton",
        `background-color: ${colors.primary};color: black;border: none;border-radius: 8px;padding: 12px 16px; width: 100%;font-size: 14px; font-weight:700; cursor: pointer;`,
        t("payments.continue"),
        "362199093",
        "1",
        generatedId
      );
    }

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [scriptLoaded]);

  return (
    <div className="mt-3">
      <div
        class="input-wrapper"
        style={{
          paddingLeft: "10px",
          paddingRight: "10px",
          marginBottom: "6px",
        }}
      >
        <input
          type="text"
          id="card-holder-name"
          style={{
            padding: "8px",
            border: "1px solid #383838",
            borderRadius: "8px",
            width: "100%",
            boxSizing: "border-box",
            background: "#383838",
            color: colors.white.normal,
          }}
          placeholder={t("payments.cardHolderName")}
          onChange={(e) => setCardHolderName(e.target.value)}
        />
      </div>
      <div class="cardinfo-card-number">
        <div class="input-wrapper" id="card-number"></div>
      </div>
      <div class="cardinfo-exp-cvv-wrapper">
        <Row className="g-0">
          <Col xs={3}>
            <div class="cardinfo-exp-date">
              <div class="input-wrapper" id="expiration-month"></div>
            </div>
          </Col>
          <Col xs={1} className="text-center">
            <div
              style={{
                color: colors.white.normal,
                fontSize: "30px",
                marginTop: 4,
              }}
            >
              /
            </div>
          </Col>
          <Col xs={3}>
            <div class="cardinfo-exp-date2">
              <div class="input-wrapper" id="expiration-year"></div>
            </div>
          </Col>
          <Col xs={5}>
            <div class="cardinfo-cvv">
              <div class="input-wrapper" id="cvv"></div>
            </div>
          </Col>
        </Row>
      </div>

      <div id="boton"></div>
      <form name="datos">
        <input type="hidden" id="token" />
        <input type="hidden" id="errorCode" />
      </form>
    </div>
  );
};

export default PaymentForm;
