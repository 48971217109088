import "./translations/i18n";
import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";
import 'simplebar-react/dist/simplebar.min.css';

// libraries
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components";
import { Tooltip } from "react-tooltip";
import SimpleBar from 'simplebar-react';
// components
import { MainRouter } from "@navigation/MainRouter";

// misc
import { theme } from "./styles/themes";
import { AuthProvider } from "@context/AuthContext";
import { SidebarProvider } from "@context/SidebarContext";
import { TabProvider } from "@context/TabContext";
import { PaymentProvider } from "@context/PaymentContext";
import { SettingsProvider } from "@context/SettingsContext";
import { StatsChartProvider } from "@context/StatsChartContext";
import { FormProvider } from "@context/FormContext";
import { CalendarProvider } from "@context/CalendarContext";
import { FilterProvider } from "@context/FilterContext";

import "react-toastify/dist/ReactToastify.css";
import "./toastStyles.css"; // Custom styles for overrides

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 15 * 60 * 1000,
      cacheTime: 5 * 60 * 60 * 1000,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
    },
  },
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <TabProvider>
        <ThemeProvider theme={theme}>
          <SidebarProvider>
            <AuthProvider>
              <PaymentProvider>
                <SettingsProvider>
                  <StatsChartProvider>
                    <FormProvider>
                    <CalendarProvider>
                    <FilterProvider>        
                    <SimpleBar style={{ height: '100vh' }}>
                      <MainRouter />
                      <ToastContainer />
                      <Tooltip id="my-tooltip" />

                      <ToastContainer
                        position="bottom-center"
                        autoClose={5000}
                        hideProgressBar
                        closeOnClick
                        pauseOnHover
                        draggable
                      />
                      </SimpleBar>
                      </FilterProvider>
                      </CalendarProvider>
                    </FormProvider>
                  </StatsChartProvider>
                </SettingsProvider>
              </PaymentProvider>
            </AuthProvider>
          </SidebarProvider>
        </ThemeProvider>
      </TabProvider>
    </QueryClientProvider>
  );
};

export default App;
