import Heading from "@components/font/heading";
import { Icon } from "@iconify/react";
import { theme } from "@styles/themes";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import backBtn from "@assets/icons/arrowLeft.svg";
import { useSidebar } from "@context/SidebarContext";

const ProfileHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location?.pathname;
  const { setShowDrawer, setIsNavigation } = useSidebar();

  const handleCross = () => {
    if (pathname === "/profile/billingdetails") {
      navigate("/profile/billingdetails/add");
    } else {
      navigate(-1);
      setShowDrawer(false);
      setIsNavigation(false)
    }
  };

  return (
    <div>
      <div
        className="d-flex align-items-center justify-content-between"
        style={{
          borderBottom: "0.5px solid",
          borderBottomColor: theme.colors.white.dark,
          padding: "14px 16px 14px 16px",
          height: "55px",
          position: "fixed",
          top: 0,
          zIndex: 2,
          backgroundColor:"rgb(26, 26, 26)",
          width:"100%",
        }}
      >
        <div className="d-flex align-items-center">
          <img
            alt="arrow-button"
            src={backBtn}
            width="24px"
            height="24px"
            className="me-3"
            onClick={() => {
              setShowDrawer(true);
              setIsNavigation(true);
              navigate(-1);
            }}
            style={{ cursor: "pointer" ,color:theme.colors.white.normal}}
          />

          <Heading
            fontSize="20px"
            title={
              pathname === "/profile/mydata"
                ? "profile.myAccount"
                : pathname === "/profile/billingdetails"
                ? "profile.billingDetails"
                : pathname === "/profile/billingdetails/add"
                ? "profile.addBillingDetails"
                : pathname === "/profile/setting"
                ? "profile.setting"
                : pathname === "/profile/contactus"
                ? "profile.contactUs"
                : null
            }
            fontWeight="700"
            color={theme.colors.white.normal}
          />
        </div>
        <div className="d-flex align-items-center">
          <Icon
            onClick={handleCross}
            icon={
              pathname === "/profile/billingdetails"
                ? "f7:plus-app"
                : "bitcoin-icons:cross-filled"
            }
            width="24"
            height="24"
            color={theme.colors.white.normal}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
