import React, { createContext, useContext, useState } from "react";

interface FilterContextType {
  filterOpen: Boolean;
  setFilterOpen: React.Dispatch<React.SetStateAction<Boolean>>;
}

const FilterContext = createContext<FilterContextType | undefined>(undefined);

export const FilterProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [filterOpen, setFilterOpen] = useState<Boolean>(false);

  return (
    <FilterContext.Provider value={{ filterOpen, setFilterOpen }}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilter = () => {
  const context = useContext(FilterContext);
  if (context === undefined) {
    throw new Error("Filter must be used within a FilterProvider");
  }
  return context;
};