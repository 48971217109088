import CountryCodePicker from "@components/form/phoneInput";
import { TextField } from "@components/form/textField";
import { theme } from "@styles/themes";
import { countryCodes } from "@utils/countries";
import { regexPatterns } from "@utils/regex";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const StepOne = ({ control, setValue, errors,trigger }: any) => {
  const { t } = useTranslation();

  return (
    <div>
      <Row className="g-4">
        <Col md={6} sm={12} xs={12}>
          <TextField
           floatingLabel
            control={control}
            name="firstName"
            bgColor={theme.colors.grey.dark}
            size="sm"
            onBlur={(e) => {
              trigger("firstName");
            }}
            placeholder={t("signUp.name")}
            errors={errors.firstName}
            rules={{
              required: t("errors.required"),
              pattern: {
                value:
                  regexPatterns?.regOnlyChar,
                message: t("errors.namePattern"),
              },
            }}
          />
        </Col>
        <Col md={6} sm={12} xs={12}>
          <TextField
            floatingLabel
            control={control}
            name="lastName"
            bgColor={theme.colors.grey.dark}
            size="sm"
            onBlur={(e) => {
              trigger("lastName");
            }}
            placeholder={t("signUp.lastName")}
            errors={errors.lastName}
            rules={{
              required: t("errors.required"),
              pattern: {
                value:
                regexPatterns?.regOnlyChar,
                message: t("errors.namePattern"),
              },
            }}
          />
        </Col>

        <Col md={12} sm={12} xs={12}>
          <TextField
            floatingLabel
            control={control}
            name="email"
            bgColor={theme.colors.grey.dark}
            size="sm"
            onBlur={(e) => {
              trigger("email");
            }}
            placeholder={t("signUp.email")}
            errors={errors.email}
            rules={{
              required: t("errors.required"),
              pattern: {
                value: regexPatterns?.regEmail,
                message: t("errors.emailPattern"),
              },
            }}
          />
        </Col>
        <Col md={3} sm={3} xs={5} className="d-flex align-items-start">
          <CountryCodePicker
            bgColor={theme.colors.grey.dark}
            size="sm"
            options={countryCodes}
            placeholder={t("signUp.prefix")}
            control={control}
            name="phonePrefix"
            setValue={setValue}
            errors={errors.phonePrefix}
            rules={{ required: false }}
          />
        </Col>
        <Col md={9} sm={9} xs={7} className="d-flex align-items-end">
          <TextField
          floatingLabel
           style={{paddingLeft:'6px'}}
            control={control}
            name="phoneNumber"
            bgColor={theme.colors.grey.dark}
            size="sm"
            onBlur={(e) => {
              trigger("phoneNumber");
            }}
            placeholder={t("signUp.phoneNumber")}
            errors={errors.phoneNumber}
            rules={{
              required: false,
              pattern: {
                value: regexPatterns?.regOnlyNum,
                message: t("errors.phoneNumberPattern"),
              },
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default StepOne;
