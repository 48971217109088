export const terms = `1.	OBJETO DE LAS CONDICIONES GENERALES DE LA CONTRATACIÓN

Las presentes condiciones generales de la contratación (en adelante, las "CGC" y/o las "Condiciones Generales de la Contratación") regulan expresamente las relaciones contractuales surgidas entre el titular de esta página web y los usuarios consumidores que, a través de este sitio web https://app.inbetia.com/ (en adelante, la "Plataforma" o la "Web"), titularidad de DRACAL, S.L. (en adelante, la "Sociedad"), contraten los servicios ofertados en cada momento.
El objeto de este documento es establecer las condiciones que regularán la contratación, por parte del cliente, de alguno de los servicios ofrecidos por la Sociedad a través de la página Web y con las finalidades dispuestas. 
Estas Condiciones Generales de la Contratación han sido elaboradas de conformidad con los establecido en la legislación española Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de comercio electrónico (LSSI-CE), la Ley 7/1998, sobre Condiciones Generales de la Contratación (LCGC), y del RDL 1/2007, de 16 de noviembre, por el que se aprueba el Texto Refundido de la Ley General para la Defensa de los Consumidores y Usuarios y otras leyes complementarias (TRLGDCU) así como también de conformidad con la legislación andorrana, la Llei 13/2013, del 13 de juny, de competencia efectiva i protecció del consumidor y en la Llei 20/2014, del 16 d’octubre, reguladora de la contractació electrònica i dels operadors que desenvolupen la seva activitat econòmica en un espai digital.
En caso de ser aceptadas por el cliente, las presentes Condiciones Generales de la Contratación sustituirán plenamente a las condiciones vigentes hasta dicho momento, comenzando a surtir efecto en las nuevas adquisiciones contratadas por el cliente, a partir de la fecha de aceptación de las nuevas Condiciones Generales de la Contratación.
Cualquier cuestión que no hubiera sido expresamente prevista en las presentes Condiciones Generales de la Contratación se entenderá reservada a la Sociedad, sin perjuicio de la aplicación de lo dispuesto en la normativa vigente.

Al utilizar esta página Web, los usuarios declaran conocer y aceptar los diferentes trámites necesarios para acceder a los diferentes servicios ofrecidos por la Sociedad, así como para formalizar la contratación de los mismos. De la misma manera, resulta preceptivo haber aceptado previamente los Términos y Condiciones de la Web.
El cliente acepta, entiende y comprende las presentes Condiciones Generales de la Contratación al realizar una contratación con la Sociedad a través de esta página Web, teniendo previo conocimiento de su contenido por habérsele proporcionado un ejemplar de las mismas en un formato electrónico duradero, además de hallarse las mismas publicadas en esta misma página Web. Asimismo, el cliente asume las obligaciones aquí dispuestas. 
Si desea descargar y obtener una copia de las presentes Condiciones Generales de la Contratación, puede obtenerla haciendo clic AQUÍ las cuales se encuentran a disposición de todos los clientes de la Plataforma de forma libre y gratuita.
Las presentes CGC constituyen un contrato legalmente vinculante y usted tiene el deber de leerlas, y no deberá continuar con la compra de los servicios ofrecidos en caso de no aceptar los términos que se detallan a continuación. 
El hecho de seguir telemáticamente todos los pasos de éste supone la aceptación expresa de las presentes Condiciones Generales de la Contratación por parte del cliente, teniendo la misma validez que la firma presencial del mismo. 
Así mismo, se recomienda al cliente leer atentamente nuestro Aviso Legal, la Política de Privacidad, y la Política de Cookies antes de realizarse la contratación de los servicios de la Sociedad. 
El idioma en que se tramitará el procedimiento de contratación y en el que se formalizarán las presentes Condiciones Generales de la Contratación será, salvo indicación en contrario, el castellano.

2.	IDENTIFICACIÓN DE LAS PARTES

Por un lado, los datos de la Sociedad son los siguientes:
o	Nombre/ Razón Social: 			DRACAL, S.L.;
o	NRT: 					L-719916-X;
o	Domicilio social: 				Ctra. de la Rabassa Edifici Superlauredià 
III, 1-2, AD600, Sant Julià de Lòria, Principat d’Andorra;
o	Correo electrónico: 			support@inbetia.com;
o	Número de Registro: 			24842;
Por el otro lado, los datos del cliente (en adelante, el "Cliente", y/o los "Clientes"), serán los indicados por él mismo a través del formulario de registro de creación de usuario de la Web. 
Puedes visitar nuestra "Política de Privacidad" para obtener información más detallada sobre el tratamiento de los datos personales. 
Se considerará Cliente a cualquier persona física o jurídica, o entidad sin personalidad jurídica, que contrate los servicios a través de la página Web de la Sociedad y que, acumulativamente, cumpla los siguientes requisitos:
•	En caso de ser una persona física, deberá ser mayor de 18 años y con capacidad legal suficiente para contratar. 
•	Se considerará consumidor a cualquier persona física o jurídica, o entidad sin personalidad jurídica, que cumpla los requisitos del artículo 3 del RDL 1/2007 por el que se aprueba el texto refundido de la Ley General para la Defensa de Consumidores y Usuarios y/o el artículo 2 f) de la ley andorrana Llei 13/2013, del 13 de juny, de competencia efectiva i protecció del consumidor.

3.	OBJETO DE LA SOCIEDAD

La Sociedad tiene por objeto la prestación de servicios automatizados de gestión de apuestas en plataformas de terceros mediante el uso de software (bots) desarrollado para optimizar las decisiones de apuesta dentro de las cuentas de los Clientes particulares. Estos servicios incluyen, pero no se limitan a, la automatización de apuestas en eventos deportivos u otras actividades permitidas en las plataformas de apuestas donde los Clientes posean cuentas, siguiendo parámetros previamente establecidos por los Clientes o por algoritmos diseñados por la Sociedad.
La Sociedad no opera como una plataforma de apuestas ni actúa como intermediario financiero, sino que proporciona un servicio de automatización basado en los permisos otorgados por el Cliente para actuar en su cuenta. 
La Sociedad no garantiza rendimientos o beneficios, y el uso de la plataforma implica la aceptación de los riesgos inherentes a las actividades de apuestas.

4.	EL BOT

La Plataforma utiliza software automatizado (el "Bot") diseñado para ejecutar apuestas en las cuentas de los Clientes en plataformas de terceros, de acuerdo con los parámetros configurados por defecto según los algoritmos desarrollados por la Sociedad. El Bot realizará las apuestas de forma continua y automática sin necesidad de intervención manual del Cliente, basándose en la información y estrategias proporcionadas o seleccionadas por el Cliente.
El Cliente puede personalizar ciertos parámetros que definirán la estrategia de apuesta del Bot, tales como el tipo de eventos, montos a apostar, y otros factores relevantes. Cualquier ajuste o modificación en estos parámetros es responsabilidad exclusiva del Cliente. La Sociedad no garantiza que la configuración elegida por el Cliente genere resultados positivos ni evite pérdidas.
Una vez activado, el Bot opera de manera autónoma y continuará ejecutando apuestas hasta que el Cliente decida pausar o detener su funcionamiento a través de las herramientas provistas en la plataforma. El Cliente reconoce y acepta que, durante el periodo en que el Bot esté activo, las apuestas serán ejecutadas en tiempo real y no podrán ser canceladas o modificadas retroactivamente.
El Bot también dejará de realizar apuestas de forma automática en el momento en que se agoten los créditos del Cliente en su cuenta dentro de la Plataforma, hasta que se realicen nuevos depósitos o se restablezcan los fondos necesarios para continuar con las operaciones.
En el caso de que la casa de apuestas cierre la cuenta del Cliente por cualquier razón que dicha casa estime pertinente, el Bot dejará de funcionar automáticamente. La Sociedad no asumirá responsabilidad alguna por la interrupción del servicio derivada del cierre de la cuenta del Cliente por parte de la casa de apuestas. Es responsabilidad exclusiva del Cliente mantener activa su cuenta en la casa de apuestas para el correcto funcionamiento del Bot.
La Sociedad no asume ninguna responsabilidad por errores, fallos técnicos, o malfuncionamientos del Bot que puedan resultar en apuestas no deseadas, apuestas erróneas, o pérdidas económicas. El Cliente reconoce que el uso del Bot conlleva riesgos inherentes, como fallos en la comunicación con las plataformas de apuestas o inexactitudes en los datos procesados.
De igual forma, debido a la complejidad técnica del Bot y a su desarrollo constante, la Sociedad no asume en ningún momento la obligación de mantener activas todas o algunas de las funcionalidades de este, pudiendo interrumpirse los servicios prestados. En consecuencia, la Sociedad se reserva el derecho de modificar, suspender o desactivar cualquier funcionalidad del Bot, sin previo aviso y sin que ello genere responsabilidad alguna por su parte.
En caso de cualquier interrupción del servicio, ya sea por fallos técnicos o mantenimiento de la plataforma, la Sociedad no será responsable por las apuestas no realizadas, apuestas pendientes, o por el resultado de cualquier actividad de apuestas en curso. El Cliente asume la responsabilidad de monitorear el estado del Bot y tomar las medidas necesarias si detecta cualquier irregularidad o interrupción.
El Cliente acepta que los rendimientos generados por las apuestas realizadas por el Bot pueden variar considerablemente y que no existe ninguna garantía de obtener beneficios. La Sociedad no será responsable por cualquier pérdida total o parcial de los fondos del Cliente. Asimismo, el Bot no asegura ningún tipo de rendimiento futuro ni tiene la capacidad de prever resultados en las apuestas realizadas.
El Cliente reconoce y acepta que es el único responsable de las consecuencias financieras derivadas de las apuestas realizadas por el Bot en su cuenta. La Sociedad actúa únicamente como facilitador del servicio de automatización y no se involucra en la toma de decisiones relativas a las apuestas más allá de la programación del Bot según los parámetros indicados.

5.	EXENCIONES

La Sociedad se exime de toda responsabilidad frente a las casas de apuestas en los casos en que estas consideren que el uso de los servicios ofrecidos por la Plataforma por parte del Cliente, incluido el uso del Bot para realizar apuestas automatizadas, incumple los términos y condiciones, políticas de uso o cualquier normativa interna de dichas casas de apuestas. Será responsabilidad exclusiva del Cliente asegurarse de que el uso de la Plataforma y los productos proporcionados por la Sociedad es compatible con los términos de uso de las casas de apuestas en las que el Cliente mantiene cuentas. En caso de cualquier conflicto derivado de dicho uso, el Cliente asume todas las consecuencias, incluyendo sanciones, cierres de cuentas o retenciones de fondos, sin que la Sociedad tenga ninguna responsabilidad ni obligación de compensación.
Asimismo, la Sociedad no será responsable de los errores, inexactitudes o falsedades que el Cliente pueda haber proporcionado a las casas de apuestas en el marco de los procedimientos de verificación de identidad (KYC) u otros procesos requeridos por estas, tales como la remisión de información bancaria o personal. Si las casas de apuestas deciden tomar medidas como la restricción, suspensión o cancelación de la cuenta del Cliente debido a la información incorrecta o insuficiente proporcionada por este, la Sociedad no asumirá ninguna responsabilidad ni estará obligada a intervenir o mediar en la resolución de dicha situación.
En consecuencia, cualquier consecuencia derivada de la interpretación, por parte de las casas de apuestas, de que el uso de los servicios de la Plataforma infringe sus políticas, o de que la información proporcionada por el Cliente es incorrecta o insuficiente, será de exclusiva responsabilidad del Cliente. La Sociedad no tendrá ninguna obligación de intervenir, mediar o compensar al Cliente en caso de sanciones, retenciones de fondos, cierres de cuenta o cualquier otra medida adoptada por las casas de apuestas.
Además de lo anteriormente expuesto, la Sociedad se exime de toda responsabilidad en caso de que el Cliente incurra en cualquier tipo de irregularidad, ya sea de naturaleza administrativa o penal, en el uso de los servicios ofrecidos por la Plataforma, incluido el uso del Bot para realizar apuestas automatizadas. Será responsabilidad exclusiva del Cliente cumplir con todas las leyes, reglamentos y normativas aplicables en relación con su actividad, incluyendo, pero no limitándose a las regulaciones sobre juegos de azar, prevención del blanqueo de capitales, fiscalidad y cualquier otra normativa pertinente.

En caso de que el Cliente cometa actos que constituyan infracciones administrativas o delitos, incluyendo, pero no limitándose a fraude, manipulación de resultados, uso de información privilegiada, suplantación de identidad o cualquier otra conducta ilícita, la Sociedad no asumirá ninguna responsabilidad por las consecuencias derivadas de tales actos. Esto incluye, sin limitación, sanciones legales, reclamaciones de terceros, pérdidas económicas, daños reputacionales, cierres de cuentas o cualquier otra medida que pueda ser adoptada por autoridades competentes o por las casas de apuestas.

6.	IMPAGOS DE LAS CASAS DE APUESTAS

En el caso de que una casa de apuestas de terceros decida no abonar las cantidades apostadas o los beneficios generados en la cuenta del Cliente debido a incumplimientos o acciones imputables exclusivamente al Cliente, tales como violación de las políticas de la casa de apuestas, incumplimiento de los términos y condiciones de dicha plataforma, uso indebido de la cuenta o cualquier otra razón que sea atribuible al Cliente, las cantidades apostadas por el Cliente no serán reembolsables en ningún caso. El Cliente acepta asumir plena responsabilidad por dichas acciones y reconoce que la Sociedad no tiene la obligación de compensarle por las pérdidas derivadas de tal situación.
Si, por el contrario, la casa de apuestas decide efectuar un impago o retener las cantidades apostadas o los beneficios generados debido a causas imputables exclusivamente a la Sociedad, tales como errores en el uso del Bot o incumplimientos contractuales por parte de la Sociedad, la única compensación al Cliente será la devolución de los créditos utilizados para realizar la apuesta afectada por el Bot. En ningún caso se devolverá dinero real ni se podrán reclamar otras cantidades adicionales a la Sociedad, incluidos beneficios potenciales o pérdidas generadas por el impago. El Cliente acepta que dicha devolución de créditos es la única compensación disponible en estos casos y renuncia a cualquier otra reclamación contra la Sociedad.
La Sociedad no será responsable, en ningún caso, por impagos, retrasos o retenciones de fondos por parte de las casas de apuestas que no sean atribuibles directamente a un incumplimiento de la Sociedad. Cualquier disputa entre el Cliente y la casa de apuestas deberá resolverse directamente entre dichas partes, sin perjuicio de las responsabilidades del Cliente bajo esta cláusula.

7.	REGISTRO PARA EL ACCESO A LA CONTRATACIÓN

Para que la Sociedad pueda prestar sus Servicios es necesario que el Cliente se registre previamente en la Plataforma y adquiera una cuenta exclusiva para él. 
En este sentido, el Cliente deberá rellenar el formulario de registro a la página Web con su información de carácter personal, que deberá ser veraz y estar actualizada. 
Al completar el proceso de registro, el Cliente acepta proporcionar información veraz, exacta y de carácter personal. Además, a su exclusivo criterio, la Sociedad puede negarse a otorgar un nombre de usuario para la cuenta en particular. 
La cuenta será personal para usted y no podrá compartirla con nadie más. El Cliente será responsable de mantener la confidencialidad de su nombre de usuario y contraseña. 
Tras haber completado el formulario, el Cliente deberá aceptar la Política de Privacidad de la Plataforma, así como el Aviso Legal, antes de finalizar con el proceso de registro.
La contraseña de acceso introducida deberá mantenerse por parte del Cliente en un lugar seguro, siendo responsabilidad exclusiva del mismo. Una vez registrado, el Cliente podrá modificar su contraseña a través de la configuración de su perfil, o mediante la viñeta correspondiente si se ha olvidado la contraseña.
En el caso que detecte algún uso sospechoso o indebido de su información de registro (identificador y contraseña) póngase en contacto inmediatamente con la Sociedad a través del correo electrónico indicado por el Cliente. 

8.	CONTRATACIÓN ELECTRÓNICA Y SISTEMA DE COMPRA

De conformidad con lo dispuesto en la legislación vigente, los contratos celebrados por vía electrónica producirán todos los efectos previstos por el ordenamiento jurídico, cuando concurran el consentimiento y los demás requisitos necesarios para su validez.
En todo caso, el soporte electrónico en que consten las presentes Condiciones Generales de la Contratación celebrado por vía electrónica será admisible como prueba documental en caso de controversia entre las partes.

Del mismo modo, y atendiendo a lo dispuesto en la legislación contractual, se pone a disposición de los Clientes, de forma previa al inicio del procedimiento de contratación, toda la información relativa al mismo, que únicamente se aplicará en caso de que el Cliente decida proceder a la contratación a través de algún servicio.
Las compras realizadas a través de esta página Web deberán hacerse siguiendo el procedimiento de compra on-line establecido.  El Cliente podrá navegar por la página Web y/o buscar los productos deseados. 
El acceso al proceso de contratación es completamente gratuito, sin que exista ningún coste asociado adicional aparte de los propios derivados de la disposición de conexión a internet por parte del Cliente. Una vez localizado un determinado producto, el Cliente podrá acceder a la descripción completa del mismo, en la cual se le informará, entre otros datos, de los siguientes extremos: precio de los productos adquiridos, contenido de los productos adquiridos, tiempo durante el cual el producto podrá ser usado y precio.
Los productos ofrecidos por la Sociedad consistirán en la adquisición de créditos dentro de la Plataforma, los cuales podrán ser utilizados para el funcionamiento del Bot automatizado que ejecuta apuestas en las cuentas de los Clientes.
Para adquirir créditos, el Cliente deberá seleccionar la cantidad deseada y hacer clic en la opción "Adquirir Créditos". Posteriormente, el Cliente tendrá acceso al resumen de su compra, donde se podrán revisar los créditos añadidos, el precio unitario y el precio total de la transacción.
Para finalizar la compra, el Cliente deberá proporcionar los datos personales solicitados, seleccionar uno de los medios de pago disponibles, y proceder al pago de los créditos adquiridos. La Sociedad utiliza un proveedor externo de servicios de pago que cumple con los estándares de seguridad necesarios para proteger la información financiera del Cliente. 
Para obtener más información sobre las medidas de seguridad, el Cliente podrá consultar la documentación proporcionada por el proveedor de pagos al momento de la transacción.
Una vez realizado el pedido, la Sociedad enviará un correo de confirmación a la dirección de correo electrónico proporcionada por el Cliente, confirmando la adquisición de los créditos. 
Los créditos adquiridos por el Cliente serán acreditados en su cuenta dentro de la Plataforma y estarán disponibles para el uso del Bot automatizado en la ejecución de apuestas. Los créditos podrán ser utilizados exclusivamente dentro de la Plataforma, de acuerdo con los términos establecidos por la Sociedad.
En caso de que la Sociedad decida modificar el precio o valor de los créditos en el futuro, se compromete a que dicha modificación no afectará a los créditos ya adquiridos por el Cliente, garantizando que estos no se verán deflacionados ni perjudicarán al Cliente de forma alguna. Los créditos ya acreditados en la cuenta del Cliente mantendrán su valor original, independientemente de los cambios futuros que la Sociedad pueda realizar en el precio de adquisición de nuevos créditos.
Los Servicios ofrecidos por la Sociedad se describen en la Plataforma y en las presentes Condiciones Generales de la Contratación, no entendiéndose incluidas aquellas cuestiones que expresamente no hayan sido indicadas.
El contrato entre la Sociedad y el Cliente se perfecciona desde el momento en que el Cliente finaliza el procedimiento de contratación, presionando el botón de "He leído y Acepto las presentes Condiciones Generales de la Contratación y la Política de Privacidad". El seguimiento de todas las fases del procedimiento de contratación electrónica y la inclusión de todos los datos solicitados suponen, junto con la marcación de la casilla correspondiente, la aceptación de las Condiciones Generales de la Contratación.
La Sociedad archivará el documento electrónico en el que se formaliza el presente contrato. Dicho documento será accesible para el Cliente mediante un enlace que se le facilitará por correo electrónico, desde donde podrá descargarlo e imprimirlo, incluyendo las presentes Condiciones Generales de la Contratación.
La Sociedad introducirá los medios técnicos necesarios para identificar y corregir errores técnicos en la gestión de la información dentro de sus responsabilidades.
El idioma en el que se formalizará el procedimiento de contratación será, salvo indicación en contrario, el español. En caso de que cualquiera de los textos legales, incluidas las presentes Condiciones Generales de Contratación, se presente en otro idioma, dicho texto será considerado una traducción. En caso de discrepancia entre la traducción y la versión en español, prevalecerá lo estipulado en esta última.
Precio
El precio vigente de los Servicios ofrecidos por la Sociedad será el indicado en cada momento a través del apartado "Tienda" en la Plataforma junto a su descripción. Los precios están representados en EUROS (EUR), y, si la tarjeta de crédito/débito del Cliente no está denominada en EUROS, el tipo de cambio será aplicado por la entidad bancaria del Cliente según el tipo de cambio establecido a su única discreción. La Sociedad no será responsable de las conversiones de moneda ni de los cargos aplicados por las entidades bancarias.
El precio podrá reflejarse también en Dólares Estadounidenses (USD) o en un activo digital de tipo stablecoin que la Sociedad decida utilizar como medio de pago, siendo en todo caso una conversión automática del precio en EUROS, divisa original para los precios.
Los precios de los créditos y otros servicios ofertados en la Plataforma serán mostrados al Cliente de manera desglosada durante el proceso de contratación, asegurando que el Cliente conoce el coste total de su compra antes de proceder al pago. 
La Sociedad se verá facultada para actualizar los precios en cualquier momento, no viéndose obligada a mantener los mismos en ningún momento. 
El Cliente acepta que los créditos adquiridos podrán utilizarse exclusivamente dentro de la Plataforma para la operación del Bot automatizado, y que estos no son reembolsables ni transferibles.

9.	EL PAGO

Los métodos de pago disponibles en la Plataforma para acceder a los Servicios de pago incluyen transferencias monetarias a través de entidades bancarias pertenecientes a la Unión Europea y al Principado de Andorra, el uso de tarjeta de crédito o débito, así como la posibilidad de realizar pagos en Activos Digitales (criptomonedas). La Sociedad acepta el pago en Activos Digitales que se detallarán en el momento de la transacción, permitiendo a los Clientes una mayor flexibilidad en las opciones de pago.
En cualquier caso, la Sociedad utilizará una entidad autorizada para habilitar un canal seguro con encriptación de los datos de pago. La Sociedad no guardará en sus archivos la información referente a los datos de las tarjetas utilizadas.
A través de la pasarela de pago habilitada por la Sociedad y gestionada por su entidad bancaria, el Cliente deberá introducir el número de tarjeta, fecha de caducidad y código de seguridad CW2. El Cliente garantiza y se responsabiliza de que todos los datos facilitados sobre su tarjeta sean válidos. Estos datos no serán guardados ni manipulados por la Sociedad, sino que serán registrados directamente en la pasarela de pagos de la entidad financiera correspondiente.
Las transacciones con tarjeta de crédito/débito estarán sujetas a un control de seguridad y validación por parte de la entidad emisora de la tarjeta o la pasarela de pago. En aplicación de la segunda Directiva de Servicios de Pago de la Unión Europea (PSD2), es posible que, al realizar el pago de nuestros servicios, la entidad bancaria del Cliente le envíe un código SMS, notificación móvil a la App de su entidad u otro sistema similar.
Si la entidad emisora de la tarjeta o la pasarela de pago deniega la transacción, la Sociedad no será responsable de los retrasos o cancelaciones de los Servicios contratados.
La Sociedad también permite al Cliente realizar el pago de los Servicios mediante Activos Digitales (criptomonedas). Al seleccionar esta modalidad, el Cliente deberá seguir las instrucciones proporcionadas por la Sociedad para completar la transacción en la blockchain correspondiente.
El Cliente garantiza que los fondos en Activos Digitales utilizados para el pago son legítimos y que es el propietario o posee la autorización necesaria para utilizar dichos Activos Digitales.
El acceso a los productos por los cuales se hubiere realizado el pago será efectivo únicamente cuando la Sociedad reciba la confirmación de que la transacción ha sido completada exitosamente en la blockchain, o por el proveedor de pagos seleccionado. Si la transacción en Activos Digitales es denegada o si no se recibe la cantidad íntegra correspondiente al importe de los productos, estos se suspenderán o cancelarán, informando al Cliente de que la adquisición no se ha completado y debiendo realizarse nuevamente la operación.
Una vez que el Cliente haya contratado los productos y abonado el pago correspondiente a través de cualquiera de las modalidades de pago puestas a disposición por la Sociedad, se enviará un correo electrónico al Cliente en el que se recogerá toda la información relativa a los productos contratados.
El acceso a los productos será efectivo únicamente cuando la Sociedad reciba confirmación de la transacción por parte de la entidad de servicios de pago o la red de blockchain correspondiente. En caso de cualquier incidencia o retraso en la confirmación, la Sociedad se reserva el derecho de suspender temporalmente el acceso hasta que se resuelva la situación.

10.	PROBLEMAS TÉCNICOS

La Sociedad como responsable de la Web y encargado de la comercialización de los productos ofrecidos a través de estas, pone a disposición de los Clientes un servicio de atención al cliente, en el que se dará debida atención a todas las consultas, quejas y sugerencias planteadas en relación con la contratación y comprade cualquiera de los productos.
En concreto, las vías de contacto puestas a disposición de los Cliente son las siguientes:
•	Correo electrónico: 	https://app.inbetia.com/ 
•	Teléfono:			+376 62 73 81
En caso de que el Cliente detectase que se ha producido un error al introducir datos personales durante su registro como usuario de esta página Web, podrá modificar los mismos en el apartado personal del Cliente. 
En todo caso, podrá corregir los errores relacionados con los datos personales aportados durante el proceso de contratación dirigiéndose al servicio de atención al cliente a través del teléfono o la dirección de correo electrónico indicados ut supra, así como ejercitar su derecho de rectificación contemplado en nuestra Política de Privacidad a través de la dirección de correo electrónico anteriormente mencionada. 
Esta página Web muestra ventanas de confirmación en diversos apartados del proceso de compra que no permiten proseguir si los datos de estos apartados no se han proporcionado correctamente. 
Asimismo, este sitio Web facilita los detalles de todos los artículos y/o productos que haya añadido a su cesta/carrito de la compra, de forma que, antes de realizar el pago, el Cliente podrá modificar los datos de su pedido.
Si el Cliente detectase un error en su pedido con posterioridad a la finalización del proceso de pago, deberá ponerse en contacto con nuestro servicio de atención al Cliente, en el teléfono o en la dirección de correo electrónico anteriormente mencionados, para subsanar el error.

11.	USO DE LAS PLATAFORMAS

La Web proporciona a los Clientes el acceso a multitud de informaciones, servicios, o datos (los "Contenidos") pertenecientes a la Sociedad o a sus licenciantes. 
El Cliente se compromete a utilizar la Plataforma y los Productos ofrecidos por la Sociedad de conformidad con la legislación vigente, el Aviso Legal, las presentes Condiciones Generales y Particulares aplicables, así como cualquier reglamento, normativa o instrucción que la Sociedad ponga a su disposición. Asimismo, el Cliente deberá actuar con respeto a la moral, las buenas costumbres generalmente aceptadas, y el orden público.
En consecuencia, el Cliente se abstendrá de utilizar la Plataforma o los Productos con fines ilícitos, ilegales o prohibidos en las presentes CGC, o que puedan resultar lesivos para los derechos e intereses de terceros, incluidas las actividades que puedan afectar negativamente a la imagen personal y/o profesional de la Sociedad o de otros Clientes. El Cliente también se compromete a no realizar actividades que pudieran dañar, inutilizar, sobrecargar o deteriorar la normal utilización de la Plataforma, los sistemas informáticos o los archivos y contenidos almacenados en cualquier equipo o sistema de la Sociedad, de otros Clientes o de cualquier tercero.
De manera enunciativa, pero no limitativa, el Cliente se compromete a no realizar las siguientes acciones a través de la Plataforma o en relación con los Productos:
1.	Distribuir Contenidos Ilícitos: No transmitir, difundir o poner a disposición de terceros información, datos, contenidos o materiales que:
o	Atenten contra los derechos fundamentales y libertades públicas reconocidas en la legislación nacional, tratados internacionales o cualquier otra normativa aplicable.
o	Promuevan actos delictivos, difamatorios, violentos, denigratorios o que, en general, sean contrarios a la ley, la moral o el orden público.
o	Induzcan a actitudes o pensamientos discriminatorios por motivos de sexo, raza, religión, creencias, edad o condición.
2.	Contenido Dañino o Peligroso: No introducir o difundir en la Plataforma contenidos que puedan:
o	Promover prácticas peligrosas, nocivas o de riesgo para la salud física o mental.
o	Contener información falsa, ambigua o inexacta que pueda inducir a error sobre su objeto o las intenciones del comunicante.
3.	Propiedad Intelectual y Derechos de Terceros: Respetar los derechos de propiedad intelectual e industrial, así como los secretos empresariales, y no utilizar materiales protegidos sin autorización expresa de sus titulares. El Cliente debe abstenerse de divulgar información que infrinja la privacidad o el derecho al honor, intimidad personal o familiar de terceros.
4.	Seguridad y Daños a Sistemas Informáticos: No introducir virus u otros elementos físicos o electrónicos que puedan dañar o interferir con el normal funcionamiento de la Plataforma, los sistemas informáticos, el hardware o software de la Sociedad o de terceros. Queda prohibido realizar acciones que puedan sobrecargar o impedir el correcto uso de los Productos.
5.	Competencia Desleal y Publicidad Ilegal: No realizar acciones que constituyan publicidad ilícita, engañosa o desleal, ni participar en prácticas de competencia desleal que puedan dañar a la Sociedad o a otros Clientes.
6.	Uso Razonable y Responsable de la Plataforma: Los Clientes deberán hacer un uso razonable de la Plataforma y sus Productos, bajo el principio de buena fe y con respeto a la legalidad vigente, las normas de moralidad y el orden público. En todo momento, se espera que el uso de la Plataforma se ajuste a los fines para los cuales ha sido concebida, evitando comportamientos que excedan su propósito.
El Cliente es el único responsable del uso que haga de la información, contenidos, productos y servicios disponibles a través de la Plataforma. El acceso y uso de dichos recursos está supeditado al cumplimiento de la normativa aplicable y los principios de buena fe.
La Sociedad no asume responsabilidad alguna, ya sea directa o indirecta, por los daños derivados de un uso indebido de la Plataforma o los productos por parte del Cliente o de terceros, incluyendo cualquier daño emergente o lucro cesante. Asimismo, la Sociedad no será responsable por la interrupción de los servicios o fallos técnicos que puedan producirse por la acción u omisión del Cliente.

La Sociedad garantiza que no accederá ni almacenará información confidencial o personal de los Clientes más allá de lo necesario para la prestación de los servicios asociados. El Cliente se compromete a no violar la normativa relativa al secreto de las comunicaciones, absteniéndose de interceptar, modificar o manipular datos ajenos sin el consentimiento del titular.

12.	EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD

La Sociedad se reserva el derecho a interrumpir el acceso y uso de las plataformas en cualquier momento y sin previo aviso, ya sea por motivos técnicos, de seguridad, de control, de mantenimiento, por fallos de suministro eléctrico o por cualquier otra causa justificada. En consecuencia, la Sociedad no garantiza la fiabilidad, la disponibilidad ni la continuidad de las Plataformas, de los servicios ni de los contenidos albergados o los procesos activos en ellas, por lo que su empleo por parte de los Clientes es realizado bajo su propia cuenta y riesgo, sin que, en ningún momento, puedan exigirse responsabilidades a la Sociedad en este sentido.
Además, la Sociedad no asume responsabilidad alguna derivada, a título enunciativo, pero no limitativo:
•	De la utilización que los Clientes hagan de la Plataforma, ya sean prohibidos o permitidos, en infracción de los derechos de propiedad intelectual y/o industrial de contenidos de la propia Web o de los portales de terceros.
•	De los eventuales daños y perjuicios a los Clientes causados por un funcionamiento normal o anormal de las herramientas, de la organización o el acceso a la Plataforma y, en general, de los errores o problemas que se generen en el desarrollo o instrumentación de los elementos técnicos que forman los servicios.
•	De los Contenidos de aquellas páginas a las que los Clientes puedan acceder desde enlaces incluidos en las Plataformas.
•	De los actos u omisiones de terceros, con independencia de que estos terceros pudiesen estar unidos a la Sociedad mediante vía contractual.
De igual modo, la Sociedad excluye cualquier responsabilidad por los daños y perjuicios de toda clase que puedan deberse a la presencia de virus o a la presencia de otros elementos lesivos en los contenidos que puedan producir alteración en los sistemas informáticos, así como en los documentos o sistemas almacenados en los mismos, por lo que la Sociedad no será responsable en ningún caso cuando se produzcan:
•	Errores o retrasos en el acceso a los servicios por parte del Cliente a la hora de introducir sus datos en el formulario correspondiente o cualquier anomalía que pueda surgir cuando estas incidencias sean debidas a problemas en la red Internet, causas de caso fortuito o fuerza mayor y cualquier otra contingencia imprevisible ajena a la buena fe de la Sociedad.
•	Fallos o incidencias que pudieran producirse en las comunicaciones, borrado o transmisiones incompletas, de manera que no se garantiza que los servicios de las Plataformas estén constantemente operativos.
•	De los errores o daños producidos a las Plataformas por un uso deficiente y/o con mala fe de los Servicio o contenidos por parte del Cliente.
En todo caso, la Sociedad se compromete a solucionar los problemas que puedan surgir y a ofrecer todo el apoyo necesario al Cliente para llegar a una solución rápida y satisfactoria en caso de que se produzca alguna incidencia.

13.	DERECHO DE DESISTIMIENTO

Contenido del derecho de desistimiento
El derecho de desistimiento de un contrato es la facultad del Cliente de dejar sin efecto el contrato celebrado, notificándoselo así a la otra parte contratante en el plazo establecido para el ejercicio de ese derecho, sin necesidad de justificar su decisión y sin penalización de ninguna clase.
Plazo y forma del desistimiento
Si el Cliente está contratando como consumidor y usuario, tiene derecho a desistir del presente contrato en un plazo mínimo de catorce (14) días naturales sin justificación y sin penalización de ninguna clase.  El plazo de desistimiento expirará a los catorce (14) días naturales del día en que el Cliente contrate los servicios a través de la página Web de la Sociedad.
No obstante, el Cliente solo podrá ejercer su derecho de desistimiento respecto a la adquisición de los productos, como son los créditos de la Plataforma, siempre que los mismos no hayan sido consumidos los productos total o parcialmente en la Plataforma. Si el Cliente ha utilizado total o parcialmente los productos adquiridos para su uso en la Plataforma, y sea para el uso del Bot o cualquier otro producto de la Plataforma, el derecho de desistimiento no podrá ser ejercido sobre la cantidad de créditos ya consumidos.
Para ejercer el derecho de desistimiento, la Sociedad, de conformidad con lo establecido legalmente, no exigirá al Cliente ningún tipo de formalidad, siendo suficiente con realizar una comunicación por cualquier medio que permita dejar constancia del ejercicio del derecho (por correo postal, correo electrónico o a través de nuestro número de teléfono). En tal caso, la Sociedad comunicará sin demora al Cliente en un soporte duradero el acuse de recibo de dicho desistimiento. 
El Cliente habrá ejercido su derecho de desistimiento dentro del plazo de los catorce (14) días naturales contados a partir de la contratación de los servicios, cuando haya enviado la comunicación relativa al ejercicio del derecho de desistimiento antes de que finalice dicho plazo.
Gastos vinculados al desistimiento
El ejercicio del derecho de desistimiento no implicará gasto alguno para el consumidor y usuario.
Consecuencias del ejercicio del derecho de desistimiento
En caso de desistimiento por parte del Cliente, la Sociedad estará obligada a devolver todos los pagos recibidos del Cliente incluidos, en su caso, los costes de entrega y sin ninguna demora indebida y, en cualquier caso, en el plazo máximo de 14 días naturales a contar desde la fecha en la que el Cliente nos informe de su decisión de desistir del presente contrato. La Sociedad procederá a realizar el reembolso utilizando el mismo medio de pago empleado por el Cliente para la transacción inicial, a no ser que el Cliente haya dispuesto expresamente lo contrario y siempre y cuando el Cliente no incurra en ningún gasto como consecuencia del reembolso.
Transcurrido dicho plazo sin que el Cliente haya recuperado la suma adeudada, tendrá derecho a reclamarla duplicada, sin perjuicio de que además se le indemnicen los daños y perjuicios que se le hayan causado en lo que excedan de dicha cantidad.

14.	COMUNICACIONES

La normativa aplicable exige que parte de la información o comunicaciones que le enviemos a los clientes sean por escrito. Mediante el uso de esta página Web, el usuario acepta que la mayor parte de dichas comunicaciones con la Sociedad sean electrónicas. Nos pondremos en contacto con el Cliente por correo electrónico o le facilitaremos información colgando avisos en la página Web. Todas las comunicaciones serán remitidas al correo electrónico de contacto con el cual se haya registrado y/o puesto en contacto el Cliente.
A efectos contractuales, el Cliente consiente en usar este medio electrónico de comunicación y reconoce que todo contrato, notificaciones, información y demás comunicaciones que le enviemos de forma electrónica cumplen con los requisitos legales de ser por escrito. Esta condición no afectará a sus derechos reconocidos por ley. 

15.	CONFIRMACIÓN DE LOS PRODUCTOS Y LOS SERVICIOS

Las peticiones de productos y servicios realizadas por los Clientes están sujetas a (i) su aceptación por parte de la Sociedad, (ii) a que los Clientes cumplan y acepten las presentes Condiciones Generales de la Contratación y (iii) a la disponibilidad del mercado.
La Sociedad se reserva el derecho de no aceptar la operación en el caso (i) que la información de pago del Cliente no sea aprobada, o que (ii) que se detecte cualquier otro tipo de incidencia relativa a las Condiciones de Uso de la Plataforma.
Cuando el Cliente realice la contratación de un producto o servicio a través de las Plataformas, la Sociedad podrá enviarle un correo electrónico de confirmación que tendrá detalles de la transacción. Una vez recibido dicho correo, el Cliente deberá verificar que los detalles de dicho correo de confirmación sean correctos lo antes posible y guardar una copia para su registro.

16.	ERRORES Y CANCELACIONES

En caso de error, la Sociedad se reserva el derecho de subsanarlo y revisar los Servicios prestados en consecuencia. En dichas revisiones, la Sociedad podrá proceder cobrar el precio correcto o cancelar la operación y reembolsar cualquier cantidad cobrada.
La Sociedad podrá cancelar la operación en caso de no disponer el Cliente de fondos suficientes. En este caso, es el Cliente el que es responsable de tener el saldo suficiente para la realización de la operación y asume los posibles sobrecostes que su entidad bancaria o proveedor de pagos pudiera aplicar en caso de no disponer de ellos. 

17.	ÁMBITO TERRITORIAL

Los servicios ofrecidos a través de las Plataformas están dirigidos principalmente a personas físicas o jurídicas residentes en el Reino de España y en el Principado de Andorra. No obstante, la Sociedad pone dichos servicios a disposición de usuarios residentes en otras jurisdicciones, siempre que ello no contravenga la legislación aplicable en dichos territorios.
La Sociedad no garantiza que los servicios ofrecidos ni las presentes Condiciones Generales de Contratación sean conformes a las normativas y legislaciones de países distintos de España y Andorra. Por consiguiente, la contratación y utilización de los servicios por parte de residentes en otras jurisdicciones se realizará bajo su exclusiva responsabilidad. Será obligación del usuario asegurarse de que el acceso y uso de las Plataformas y de los servicios es legal y cumple con las leyes locales que le sean aplicables.
La Sociedad queda exenta de cualquier responsabilidad por el uso de los servicios en jurisdicciones donde su oferta, suministro o utilización sean contrarios a la ley o requieran autorizaciones, licencias, registros o aprobaciones de cualquier tipo que la Sociedad no haya obtenido. Asimismo, la Sociedad no asumirá responsabilidad alguna por los posibles incumplimientos legales o regulatorios que puedan derivarse del uso de los servicios por parte de Clientes no residentes en España o Andorra.

18.	RESOLUCIÓN DE CONFLICTOS ONLINE

En virtud de la normativa europea de consumo, las empresas establecidas en la Unión Europea que celebren contratos de compraventa o de prestación de servicios en línea (incluyendo los que ofrecen sus productos a través de correo electrónico) y los mercados en línea establecidos en la Unión, deben ofrecer en sus sitios web el enlace a la plataforma de resolución de litigios en línea, a través del cual se podrán tramitar reclamaciones de contrataciones realizadas online:
https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=ES
La creación y desarrollo de la Plataforma de resolución de litigios en línea viene dispuesta en el Reglamento (UE) 524/2013 del Parlamento Europeo y del Consejo, para tratar de ofrecer un mecanismo online de presentación de controversias entre consumidores y usuarios establecidos en la Unión Europea de una manera más sencilla, rápida y barata que acudir a los tribunales.
Por tanto, a través de la plataforma habilitada a través del anterior enlace, el usuario puede hacer llegar sus quejas, reclamaciones u otro tipo de comentarios que desee realizar. 
Además, la Sociedad dispone de hojas oficiales de reclamación/queja/denuncia a disposición de los consumidores y usuarios, pudiendo solicitarlas a través de los datos de contactos facilitados e indicados en la página Web. Para más información al respecto puede contactar con la oficina de consumo de su lugar de residencia.
Para el caso de los Clientes establecidos en el Principado de Andorra, la Sociedad pone a disposición de sus Clientes el ENLACE para las eventuales reclamaciones en la "Unitat de Comerç i Consum" sobre la vulneración de sus derechos, con base a la Llei 13/2013, del 13 de juny, de competència efectiva i protecció del consumidor, así como los reglamentos que la desarrollan.

19.	PROTECCIÓN DE DATOS DE CARÁCTER PERSONAL

De acuerdo con lo establecido en el Reglamento General de Protección de Datos de Carácter Personal y demás normativa aplicable en la materia, la Sociedad informa a los Clientes que los datos personales que facilite a través de las Plataformas o mediante envíos de correos electrónicos, serán tratados por la Sociedad como responsable del tratamiento con respecto a lo dispuesto en la Política de Privacidad de la Sociedad que deberá ser aceptada junto con las Condiciones de Uso en el momento de registro.
De conformidad con la legislación aplicable en materia de protección de datos personales, se informa al Cliente de que la Sociedad tratará sus datos personales, y que la falta de suministro de los datos personales solicitados por la Sociedad para la prestación de los Servicios puede dar lugar a la imposibilidad para su ejecución o el mantenimiento de la relación comercial derivada del mismo. 
El propósito del procesamiento de los datos personales del Cliente es el mantenimiento, desarrollo y control de los Términos y Condiciones, así como de las Condiciones Generales de la Contratación. La ejecución de estas Condiciones Generales de la Contratación constituye la base legal para el procesamiento de los datos personales del Cliente.
Tras la terminación de la relación de prestación de Servicios entre la Sociedad y el Cliente, por cualquier motivo, los datos personales del Cliente se conservarán hasta el final del plazo de prescripción correspondientes aplicables a cualquier responsabilidad que pueda surgir de su relación contractual, así como durante el plazo requerido para cumplir con las obligaciones legales aplicables. 
El Cliente podrá ejercitar, en su caso, los derechos de acceso, rectificación, supresión, oposición, limitación del tratamiento y portabilidad de los datos mediante el envío de una comunicación escrita a la Sociedad. Además, el Cliente puede presentar una queja ante la autoridad de protección de datos competente.

20.	RESPONSABILIDAD DEL CLIENTE

Los Cliente se compromete a cumplir con sus obligaciones legales y contractuales generadas en virtud de las presentes Condiciones Generales de la Contratación. Si el Cliente no cumple alguna de sus obligaciones u obstaculiza el cumplimiento por la otra parte de las suyas, se generará el derecho de esa otra parte a reclamar una indemnización por los daños y perjuicios causados, tanto por daño emergente como por lucro cesante.
El Cliente responderá de las infracciones en que hubiesen incurrido personalmente, quedando indemne la parte contraria frente a cualquier error, culpa o negligencia no imputable a ella, y a todo perjuicio que se derivase de dichas infracciones o errores imputables a la otra parte contratante. La Sociedad empleará todos los esfuerzos que estén comercial y técnicamente a su alcance para mantener disponibles los Servicios ofrecidos a través de las Plataformas.

21.	FUERZA MAYOR

La Sociedad no será responsable por ningún incumplimiento o retraso en el cumplimiento de alguna de las obligaciones asumidas, cuando el mismo se deba a acontecimientos que estén fuera del control razonable de la Sociedad ("Causa de Fuerza Mayor"). Las Causas de Fuerza Mayor incluirán cualquier acto, acontecimiento, falta de ejercicio, omisión o accidente que esté fuera del control razonable de la Sociedad y entre otros, con carácter ejemplificativo, los siguientes:
1.	Huelgas, cierres patronales u otras medidas reivindicativas;
2.	Conmoción civil, revuelta, invasión, amenaza o ataque terrorista, guerra (declarada o no) Amenaza o preparativos de guerra;
3.	Incendio, explosión, tormenta, inundación, terremoto, hundimiento, epidemia o cualquier otro desastre natural;
4.	Imposibilidad de uso de trenes, barcos, aviones, transportes de motor u otros medios de transporte, públicos o privados;
5.	Imposibilidad de utilizar sistemas públicos o privados de telecomunicaciones;
6.	Actos, decretos, legislación, normativa o restricciones de cualquier gobierno o autoridad.
Se entenderá que las obligaciones quedarán suspendidas durante el período en que la Causa de Fuerza Mayor continúe, y la Sociedad dispondrá de una ampliación en el plazo para cumplir dichas obligaciones por un periodo de tiempo igual al que dure la Causa de Fuerza Mayor. 
La Sociedad empleará todos los medios razonables para que finalice la Causa de Fuerza Mayor o para encontrar una solución que permita cumplir con las obligaciones a pesar de la Causa de Fuerza Mayor.

22.	INTEGRIDAD

Las presentes Condiciones Generales de la Contratación y todo documento a que se haga referencia expresa en las mismas constituyen el acuerdo íntegro existente entre el Cliente y la Sociedad en relación con el objeto de estas y sustituyen a cualquier otro pacto, acuerdo o promesa anterior convenida entre el Cliente y la Sociedad verbalmente o por escrito.
El Cliente y la Sociedad reconocen haber consentido la celebración de un contrato sin haber confiado en ninguna declaración o promesa hecha por la otra parte o que pudiera inferirse de cualquier declaración o escrito en las negociaciones entabladas por los dos antes del mismo, salvo aquello que figura expresamente mencionado en las presentes Condiciones Generales de la Contratación.
Ni el Cliente ni la Sociedad disponen de acción frente a cualquier declaración incierta realizada por la otra parte, verbal o escrita, con anterioridad a la fecha de un contrato (salvo que se hubiera hecho tal declaración incierta de forma fraudulenta) y la única acción de que dispondrá la otra parte será por incumplimiento de contrato de conformidad con lo dispuesto en las presentes Condiciones.
Si algunas de las presentes Condiciones fuesen declaradas nulas y sin efecto por resolución firme dictada por autoridad competente, los restantes pacto de las Condiciones Generales de la Contratación permanecerán en vigor, sin que queden afectados por dicha declaración de nulidad.

23.	SALVAGUARDA

Todas las cláusulas o extremos de las presentes Condiciones Generales de la Contratación deben ser interpretadas de forma independiente y autónoma, no viéndose afectadas el resto de las estipulaciones en caso de que una de ellas haya sido declarada nula por sentencia judicial, resolución arbitral firme o decisión o acto administrativo. Se sustituirá la cláusula o cláusulas afectadas por otra u otras que preserven los efectos perseguidos por las Condiciones Generales de la Contratación.

24.	MODIFICACIÓN DE LAS CONDICIONES GENERALES DE LA CONTRATACIÓN

La Sociedad podrá revisar y modificar las presentes Condiciones Generales de la Contratación en cualquier momento.

Los Clientes estarán sujetos a las políticas y condiciones vigentes en el momento en que use alguna de las Plataformas para cada operación, salvo que, por ley o decisión de organismos gubernamentales, la Sociedad deba hacer cambios con carácter retroactivo en dichas políticas, en las Condiciones Generales de la Contratación o en la Política de Privacidad, en cuyo caso, los posibles cambios afectarán también a los pedidos que usted hubiera hecho previamente.
En este sentido, la Sociedad podrá modificar en cualquier momento las presentes Condiciones Generales de la Contratación, siendo la versión aplicable en cada momento aquella que se encuentre publicada en las Plataformas. En caso de una modificación sustancial de las Condiciones Generales de la Contratación, la Sociedad se compromete a informar a los Clientes de dichos cambios. Los Clientes se comprometen igualmente a consultar de manera periódica las presentes Condiciones Generales de la Contratación para estar en conocimiento de las condiciones que le son de aplicación en cada momento.

25.	FUERO Y LEGISLACIÓN APLICABLE

La prestación de los Servicios de formación se entiende efectuada en territorio del Principado de Andorra, por lo que para cuantas cuestiones interpretativas o litigiosas pudieran plantearse, será de aplicación la legislación andorrana.
En caso de controversia, ambas partes acuerdan someterse, con renuncia a cualquier otro fuero que pudiera corresponderle, a la jurisdicción de los Juzgados y Tribunales del Principado de Andorra.
Por último, recordamos al Cliente que, en el caso de presentar una reclamación por alguna incidencia en la relación contractual, pueden presentar la reclamación oportuna rellenando el siguiente formulario electrónico.

Última actualización:  5 de diciembre de 2024`;