import Heading from "@components/font/heading";
import { theme } from "@styles/themes";
import React, { useContext, useEffect, useRef } from "react";
import bell from "@assets/icons/bell.svg";
import setting from "@assets/icons/setting.svg";
import { Icon } from "@iconify/react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import NotificationComponentDesk from "../notificationComponentDesk";
import { TabContext } from "@context/TabContext";
import { useGetNotifications } from "@api/useGetNotifications";
import { useAuth } from "@context/AuthContext";
import CopyModal from "@components/modal/CopyModal";
import SettingModal from "../settingModal";
import Dropdown from "rc-dropdown";
import "rc-dropdown/assets/index.css";
import IconButtonHover from "@components/button/Iconbutton";
import leftArrow from "@assets/icons/arrowLeft.svg";

const HoverDiv = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 8px 8px 0 0;
  padding: 12px;
  border-bottom: 1px solid ${theme.colors.grey.darker};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${theme.colors.grey
      .darker}; // Change background color on hover
  }
`;
const HoverDivTwo = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 0 0 8px 8px;
  padding: 12px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${theme.colors.grey
      .darker}; // Change background color on hover
  }
`;

const StyledContainer = styled.div`
  position: fixed;
  top: 0px;
  z-index: 2;
  padding-top: 24px;
  background: ${theme.colors.grey.darker};
  width: calc(100% - 48px);
  display: flex;
  justify-content: space-between; 
  align-items: center; 
  @media (max-width: 768px) {
    display: none; /* Hide the div on mobile */
  }
`;

const Head = ({
  title,
  backBtn = true,
}: {
  title?: string;
  backBtn?: boolean;
}) => {
  const navigate = useNavigate();
  const { setAccount, accountDetail } = useAuth();
  const { data: notificationsData } = useGetNotifications();
  const [settingOpen, setSettingOpen] = React.useState<boolean>(false);
  const { notificationCardOpen, setNotificationCardOpen } =
    useContext(TabContext);
  const [dropDown, setDropDown] = React.useState<boolean>(false);
  const notificationIsOpenRef = useRef<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      notificationIsOpenRef.current = notificationCardOpen;
    }, 100);
  }, [notificationCardOpen]);
  const handleDropDown = () => {
    if (notificationCardOpen) {
      setNotificationCardOpen(false);
      setDropDown(true);
    } else if (dropDown) {
      setDropDown(false);
    } else {
      setDropDown(true);
      setNotificationCardOpen(false);
    }
  };

  return (
    <StyledContainer>
      <div className="d-flex justify-content-between align-items-center" style={{width:'70vw'}}>
        <div className="d-flex align-items-center">
          {backBtn && (
            <IconButtonHover
              iconStyle={{ width: "30px", height: "30px" }}
              src={leftArrow}
              alt="cross"
              style={{marginRight:'12px'}}
              onClick={() => navigate(-1)}
            />
          )}
          <Heading
            title={title}
            fontSize="29px"
            fontWeight="700"
            color={theme.colors.white.normal}
          />
        </div>
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ position: "relative" }}
        >
          <div>
            <div
              style={{
                position: "relative",
                cursor: "pointer",
                alignItems: "center",
                display: "flex",
              }}
            >
              <IconButtonHover
                src={bell}
                alt="bell icon"
                style={{ marginRight: "16px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  !notificationIsOpenRef.current &&
                    setNotificationCardOpen(!notificationCardOpen);
                  notificationIsOpenRef.current =
                    !notificationIsOpenRef.current;
                }}
              />

              {notificationsData?.notifications?.find(
                (item) => item.isRead !== true
              ) && (
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    right: 12,
                    borderRadius: "50%",
                    backgroundColor: theme.colors.red.normal,
                    position: "absolute",
                    top: -4,
                  }}
                />
              )}
            </div>

            <NotificationComponentDesk />
          </div>
          <IconButtonHover
            src={setting}
            style={{ marginRight: "16px" }}
            alt="setting icon"
            onClick={() => {
              setSettingOpen(true);
              setNotificationCardOpen(false);
            }}
          />

          <Dropdown
            trigger={["click"]}
            onVisibleChange={(visible) => {
              if (!visible) {
                setDropDown(false);
              }
            }}
            overlay={
              <div style={{ cursor: "pointer", position: "relative" }}>
                <div
                  style={{
                    backgroundColor: theme.colors.grey.dark,
                    borderRadius: "8px",
                    position: "absolute",
                    display: dropDown === true ? "block" : "none",
                    top: 12,
                    right: 0,
                    width: "max-content",
                    minWidth: "14em",
                    boxShadow: "0px 0px 3px 0px rgba(255, 255, 255, 1)",
                  }}
                >
                  <HoverDiv onClick={() => navigate("/profile/mydata")}>
                    <Icon
                      icon="heroicons-outline:user"
                      width="16"
                      height="16"
                      style={{
                        color: theme.colors.white.normal,
                        marginRight: "8px",
                      }}
                    />
                    <Heading
                      title="profile.myData"
                      fontSize="14px"
                      fontWeight="400"
                      color={theme.colors.white.normal}
                    />
                  </HoverDiv>

                  <HoverDivTwo
                    onClick={() => navigate("/profile/billingdetails")}
                  >
                    <Icon
                      icon="heroicons-outline:document-text"
                      width="16"
                      height="16"
                      style={{
                        color: theme.colors.white.normal,
                        marginRight: "8px",
                      }}
                    />
                    <Heading
                      title="profile.billingDetails"
                      fontSize="14px"
                      fontWeight="400"
                      color={theme.colors.white.normal}
                    />
                  </HoverDivTwo>
                </div>
              </div>
            }
          >
            <div
              onClick={handleDropDown}
              className="d-flex justify-content-between align-items-center"
              style={{ cursor: "pointer" }}
            >
              <Heading
                title={accountDetail?.nickname || "N/A"}
                fontSize="17px"
                fontWeight="400"
                className="me-1"
                color={theme.colors.white.normal}
              />
              <Icon
                icon="fe:arrow-down"
                width="18"
                height="18"
                style={{ color: theme.colors.white.normal }}
              />
            </div>
          </Dropdown>
        </div>
      </div>
      <CopyModal
        open={settingOpen}
        setOpen={setSettingOpen}
        heading="profile.setting"
        mainContentStyle={{ padding: "0" }}
        mainContent={<SettingModal onClose={() => setSettingOpen(false)} />}
      />
    </StyledContainer>
  );
};

export default Head;
