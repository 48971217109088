import { theme } from "@styles/themes";
import React from "react";
import styled from "styled-components";

const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background: #0c0c0ccc; /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loader = styled.div`
  width: 70px; /* Width of the loader */
  height: 70px; /* Height of the loader */
  border-radius: 50%; /* Make it circular */
  border: 8px solid ${theme.colors.main.normal}; /* Set the border to transparent */
  border-top: 8px solid transparent; /* Prevent overflow on top */
  background: transparent; /* Make background transparent */
  animation: spin 1s linear infinite; /* Spin animation */
  box-sizing: border-box; /* Include border in width and height calculation */

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const FullScreenLoader: React.FC = () => {
  return (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  );
};

export default FullScreenLoader;
