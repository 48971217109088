// libraries
import { useMutation } from "react-query";
import { AxiosError } from "axios";

// misc
import request from "@utils/request";
import { ServerResponse } from "./types";
import { useErrorHandler } from "@hooks/useErrorHandler";
import { AddAddressRequest } from "./useAddAddress";

export interface BankTransferResponse {
  bankAccount: string;
  concept: string;
}

export type PaymentResponse = ServerResponse<BankTransferResponse>;

export interface BankTransferRequest {
  paymentAmount: number;
  currency: number;
  isAcceptedTerms: boolean;
  billingDetails: AddAddressRequest;
}

export const useBankTransfer = ({
  onError,
  onSuccess,
}: {
  onSuccess?: (res: PaymentResponse) => void;
  onError?: (res: AxiosError<ServerResponse<unknown>>) => void;
} = {}) => {
  // variables
  const { triggerError } = useErrorHandler();

  // request
  const query = useMutation<
    PaymentResponse,
    ServerResponse<unknown> | undefined,
    BankTransferRequest
  >(["bankTransfer"], async (data: BankTransferRequest) => {
    try {
      const res = await request<PaymentResponse>({
        method: "post",
        url: `/payments/banktransfer`,
        data: { ...data },
      });

      onSuccess && onSuccess(res.data);
      return res.data;
    } catch (error) {
      onError && onError(error);
      triggerError({ error });
      throw error; // important to throw the error for react-query to recognize it
    }
  });

  // return
  return query;
};
