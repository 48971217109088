/* eslint-disable @typescript-eslint/no-empty-function */
// libraries
import React, { createContext, useContext, useReducer, useState } from "react";

interface DefaultValue {
  toggleSidebar(): void;
  isSidebarOpen: boolean;
  showDrawer:boolean;
  setShowDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  isNavigation:boolean;
  setIsNavigation: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultValue: DefaultValue = {
  toggleSidebar: () => null,
  isSidebarOpen: false,
  showDrawer: false,
  setShowDrawer: () => false,
  isNavigation: false,
  setIsNavigation: () => false,
};

const SidebarContext = createContext(defaultValue);

export const SidebarProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [isSidebarOpen, toggleSidebar] = useReducer(
    (oldValue) => !oldValue,
    true
  );

  const [showDrawer, setShowDrawer] = useState(false);
  const [isNavigation, setIsNavigation] = useState(false);

  return (
    <SidebarContext.Provider
      value={{
        isSidebarOpen,
        toggleSidebar,
        showDrawer,
        setShowDrawer,
        isNavigation,
        setIsNavigation
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => useContext(SidebarContext);
