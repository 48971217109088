import React from "react";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate } from "react-router-dom";
import Heading from "@components/font/heading";
import { theme } from "@styles/themes";
import { useTranslation } from "react-i18next";

const BottomBar = () => {
  const { t } = useTranslation();

  const Paths = [
    { name: t("sidebar.dashboard"), link: "/dashboard" },
    { name: t("sidebar.profiles"), link: "/profile" },
    { name: t("sidebar.stats"), link: "/stats" },
    { name: t("sidebar.payments"), link: "/payments" },
  ];

  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location?.pathname;
  return (
    <div
      style={{
        position: "fixed",
        borderTop: "0.5px solid",
        borderTopColor: theme.colors.white.dark,
        bottom: 0,
        left: 0,
        width: "100%",
        backgroundColor: theme.colors.grey.darker,
        textAlign: "center",
        padding: "9px 16px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        {Paths.map((option, index) => (
          <div
            key={option.link}
            onClick={() => navigate(option.link)}
            style={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Icon
              icon={
                index === 0
                  ? "majesticons:home-line"
                  : index === 1
                  ? "heroicons-outline:user"
                  : index === 2
                  ? "heroicons-outline:chart-bar"
                  : "heroicons:circle-stack"
              }
              color={
                pathname.includes(option.link)
                  ? theme.colors.main.normal
                  : theme.colors.white.normal
              }
              width={24}
              style={{ marginBottom: "4px" }}
            />
            <Heading
              fontSize="12px"
              title={option.name}
              fontWeight="400"
              color={
                pathname.includes(option.link)
                  ? theme.colors.main.normal
                  : theme.colors.white.normal
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BottomBar;
