import { TabContext } from "@context/TabContext";
import { theme } from "@styles/themes";
import React, { useEffect } from "react";
import StepOne from "./StepOne";
import { useForm } from "react-hook-form";
import Heading from "@components/font/heading";
import { Container } from "react-bootstrap";
import { Button } from "@components/button/button";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import StepFive from "./StepFive";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCheckEmail } from "../../../../api/useCheckEmail";
import { useCheckNickname } from "../../../../api/useCheckNickname";
import { useSignUp } from "../../../../api/useSignUp";
import FullScreenLoader from "@components/loader/Loader";
import AlertModal from "@components/modal/AlertModal";
import styled from "styled-components";
import { Controller } from "react-hook-form";

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 0;
`; 

export const Checkbox = styled.input.attrs({ type: "checkbox" })`
  appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid white;
  position: relative;
  cursor: pointer;

  &:checked {
    background-color: ${theme.colors.main.normal}; /* Custom check color */
    border: 1px solid black;
  }

  &:checked::after {
    content: "";
    position: absolute;
    top: 1px;
    left: 4px;
    width: 5px;
    height: 9px;
    border: solid black;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

export const Label = styled.label`
  margin-left: 8px;
  margin-bottom: 2px;
  font-size: 14px;
  cursor: pointer;
  color: ${theme.colors.white.normal};
`;


const StepperMobile = () => {
  const { t } = useTranslation();

  const stepTitles = [
    t("signUp.tellUsAboutYourself"),
    t("signUp.chooseYourNickname"),
    t("signUp.createASecurePassword"),
    t("signUp.receiveNotificationsViaTelegram"),
    t("signUp.readTermsAndConditions"),
  ];

  const { signUpTab, setSignUpTab } = React.useContext(TabContext);
  const [completedSteps, setCompletedSteps] = React.useState<number[]>([]);
  const navigate = useNavigate();
  const {
    control,
    setValue,
    watch,
    setError,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phonePrefix: null,
      phoneNumber: null,
      nickname: "",
      password: "",
      confirmPassword: "",
      radio_select: "",
      telegramUser: "",
      termsConditions: "",
    },
  });

  const {
    mutate: startSignUp,
    isLoading: isSignUpLoading,
    isError: isSignUpError,
    isSuccess: isSignUpSuccess,
    reset: resetSignUp,
  } = useSignUp();

  const checkEmail = useCheckEmail({
    onSuccess: (res) => {
      if (res.success) {
        setSignUpTab(1);
      }
    },
    onError: (err) => {
      if (err.response?.status === 409) {
        setError("email", {
          type: "manual",
          message: t("signUp.emailAlreadyRegistered"),
        });
      }
    },
  });

  const checkNickname = useCheckNickname({
    onSuccess: (res) => {
      if (res.success) {
        setSignUpTab(2);
      }
    },
    onError: (err) => {
      if (err.response?.status === 409) {
        setError("nickname", {
          type: "manual",
          message: t("signUp.usernameAlreadyExist"),
        });
      }
    },
  });

  const handleCheckEmailExist = () => {
    const email = watch("email");
    checkEmail.mutate({ email });
  };

  const handleCheckNickNameExist = () => {
    const nickname = watch("nickname");
    checkNickname.mutate({ nickname });
  };

  const onSubmit = async (values: any) => {
    const formData = {
      nickname: values?.nickname,
      email: values?.email,
      password: values?.password,
      firstName: values?.firstName,
      lastName: values?.lastName,
      phoneNumber: values?.phoneNumber,
      phonePrefix: values?.phonePrefix?.value,
      telegramUser: values?.telegramUser,
      termsConditions: values?.termsConditions,
    };

    startSignUp(formData);
  };

  const onContinue = (data: any) => {
    if (signUpTab === 0) {
      setCompletedSteps([...completedSteps, 0]);
      handleCheckEmailExist();
    } else if (signUpTab === 1) {
      setCompletedSteps([...completedSteps, 1]);
      handleCheckNickNameExist();
    } else if (signUpTab === 2) {
      setCompletedSteps([...completedSteps, 2]);
      setSignUpTab(3);
    } else if (signUpTab === 3) {
      setCompletedSteps([...completedSteps, 3]);
      setSignUpTab(4);
    } else if (signUpTab === 4) {
      setCompletedSteps([...completedSteps, 4]);
      handleSubmit(onSubmit)();
    }
  };

  const handleSignUpSuccess = () => {
    resetSignUp();
    setCompletedSteps([]);
    navigate("/login");
  };

  const handleBack = () => {
    if (signUpTab === 0) {
      navigate("/login");
    } else {
      setSignUpTab(signUpTab - 1);
      setCompletedSteps((prevSteps) =>
      prevSteps.filter((step) => step !== signUpTab - 1)
      );
    }
  };
  
  useEffect(() => {
    if (signUpTab < 0) {
      setSignUpTab(0);
    }
  }, [signUpTab]);

  return (
    <div style={{ position: "relative" }}>
      <div className="d-block d-md-none mt-3">
        <div className="d-flex align-items-center mb-3">
          {[...Array(5)].map((elem: any, index: number) => {
            return (
              <div
                onClick={() => setSignUpTab(index)}
                key={index}
                style={{
                  margin: "0 10px",
                  width: "100%",
                  borderBottom: `2px solid ${
                    signUpTab === index || completedSteps.includes(index)
                      ? theme.colors.main.normal
                      : theme.colors.grey.normal
                  }`,
                }}
              />
            );
          })}
        </div>
        <Container>
          <Heading
            title={stepTitles[signUpTab]}
            fontSize="20px"
            fontWeight="700"
            style={{ margin: "24px 0 16px 0" }}
            color={theme.colors.white.normal}
          />
          {signUpTab === 0 ? (
            <StepOne control={control} setValue={setValue} errors={errors} trigger={trigger}/>
          ) : signUpTab === 1 ? (
            <StepTwo control={control} setValue={setValue} errors={errors} trigger={trigger} />
          ) : signUpTab === 2 ? (
            <StepThree
              control={control}
              setValue={setValue}
              errors={errors}
              watch={watch}
              trigger={trigger}
            />
          ) : signUpTab === 3 ? (
            <StepFour control={control} setValue={setValue} errors={errors} trigger={trigger}/>
          ) : (
            <StepFive control={control} setValue={setValue} errors={errors} />
          )}
        </Container>
      </div>
      <div
        className="d-block d-md-none"
        style={{
          position: "fixed",
          borderTop: "0.5px solid",
          borderTopColor: theme.colors.grey.dark,
          zIndex: 100,
          bottom: 0,
          left: 0,
          width: "100%",
          backgroundColor: theme.colors.grey.darker,
          textAlign: "center",
          padding: "10px",
        }}
      >
         {
            signUpTab === 4 && (
              <CheckboxWrapper>
              <Controller
                name="termsConditions"
                control={control}
                rules={{ required: t("errors.termsConditions") }}
                render={({ field }) => (
                  <>
                    <Checkbox
                      {...field}
                      checked={!!field.value} 
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                    <Label onClick={() => field.onChange(!field.value)}>
                      {t("signUp.acceptTermsConditions")}
                    </Label>
                  </>
                )}
              />
            </CheckboxWrapper>
            )
          }
        <div className="d-flex justify-content-between" style={{gap:'16px'}}>
            <Button
              outline
              title={"signUp.back"}
              style={{
                width: "100%",
                borderRadius: "8px",
                fontWeight: "700",
                fontSize: "14px",
              }}
              onClick={handleBack}
            />
          <Button
            title={
              signUpTab === 4 ? "signIn.signUpButton" : "payments.continue"
            }
            style={{
              width: "100%",
              borderRadius: "8px",
              fontWeight: "700",
              fontSize: "14px",
            }}
            onClick={handleSubmit(onContinue)}
            isLoading={isSignUpLoading}
          />
        </div>
      </div>

      <AlertModal
        open={isSignUpError}
        type="error"
        title={"signUp.somethingWrong"}
        description={"signUp.somethingWrongDescription"}
        onActionClick={resetSignUp}
      />
      <AlertModal
        open={isSignUpSuccess}
        type="success"
        title={"signUp.success"}
        description={"signUp.successDescription"}
        onActionClick={handleSignUpSuccess}
      />
      {isSignUpLoading && <FullScreenLoader />}
    </div>
  );
};

export default StepperMobile;
