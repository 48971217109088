import { useEditMyAccount } from "@api/useEditMyAccount";
import { Button } from "@components/button/button";
import Heading from "@components/font/heading";
import { TextField } from "@components/form/textField";
import { theme } from "@styles/themes";
import { regexPatterns } from "@utils/regex";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const StyledButton = styled(Button)`
  font-size: 14px;
  font-weight: 700;
  border-radius: 8px;
  min-width: auto;
  width: 14em;
  margin-top: 12px;
  @media (max-width: 768px) {
    margin: 0;
    margin-top: 8px;

    width: 100%; /* Full width on mobile */
  }
`;

const ForgotPasswordForm = () => {
  const { t } = useTranslation();
  const { mutate } = useEditMyAccount();

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    watch,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
  });
  const onSubmit = async (values: any) => {
    const formData = {
      currentPassword: values?.currentPassword,
      newPassword: values?.newPassword,
    };
    mutate(formData);
  };

  return (
    <div className="mt-4 mb-4">
      <Heading
        title="login.forgotPassword"
        fontSize="14px"
        fontWeight="400"
        className="mb-3"
        color={theme.colors.white.normal}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className="g-3">
          <Col md={6} sm={12} xs={12}  style={{width: "600px"}}>
            <TextField
              floatingLabel
              control={control}
              name="currentPassword"
              type="password"
              onBlur={(e) => {
                trigger("currentPassword");
              }}
              bgColor={theme.colors.grey.dark}
              size="sm"
              placeholder={t('login.currentPassword')}
              errors={errors.currentPassword}
              rules={{ required: t("errors.required") }}
            />
          </Col>
          <Col md={6} sm={12} xs={12}></Col>
          <Col md={6} sm={12} xs={12}  style={{width: "600px"}}>
            <TextField
              floatingLabel
              control={control}
              name="newPassword"
              type="password"
              onBlur={(e) => {
                trigger("newPassword");
              }}
              bgColor={theme.colors.grey.dark}
              size="sm"
              placeholder={t('login.newPassword')}
              errors={errors.newPassword}
              rules={{
                required: t("errors.required"),
                minLength: {
                  value: 6,
                  message: t("errors.passwordPattern"),
                },
                pattern: {
                  value: regexPatterns?.regPassword,
                  message: t("errors.passwordPattern"),
                },
              }}
            />
          </Col>
          <Col md={6} sm={12} xs={12}></Col>
          <Col md={6} sm={12} xs={12}  style={{width: "600px"}}>
            <TextField
              floatingLabel
              type="password"
              control={control}
              name="confirmNewPassword"
              onBlur={(e) => {
                trigger("confirmNewPassword");
              }}
              bgColor={theme.colors.grey.dark}
              size="sm"
              placeholder={`${t("profile.repeatPassword")}*`}
              errors={errors.confirmNewPassword}
              rules={{
                required: t("errors.required"),
                validate: (value: any) =>
                  value === watch("newPassword") || "Passwords do not match",
              }}
            />
          </Col>
          <Col md={6} sm={12} xs={12}></Col>
          <Col md={6} sm={12} xs={12}  style={{width: "600px"}}>
            <StyledButton title="login.changePassword" outline />
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default ForgotPasswordForm;
