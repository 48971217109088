import { useGetPaymentSummary } from "@api/useGetPaymentSummary";
import { Button } from "@components/button/button";
import Heading from "@components/font/heading";
import { theme } from "@styles/themes";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAuth } from "@context/AuthContext";

const StyledCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1rem;
  margin-top: 0.75rem;
  background-color: ${theme.colors.grey.dark};
  border-radius: 8px;
  padding: 10px 16px;
  width: 100%;
  // max-width: 348px;
  height: 96px;
`;

const StyledButton = styled(Button)`
  font-weight: 700;
  font-size: 14px;
  border-radius: 8px;
  color: ${theme.colors.grey.darker};
`;

const GetCreditsCard = () => {
  const navigate = useNavigate();
  const { data: paymentSummary,isLoading } = useGetPaymentSummary();
  const { convertToAppCurrency } = useAuth();
  
  const credits = paymentSummary?.credits ? convertToAppCurrency(paymentSummary?.credits) : '';

  return (
    <StyledCard>
      <div>
        <div style={{ marginBottom: "8px" }}>
          <Heading
            title="profile.availableCredit"
            fontSize="16px"
            fontWeight="400"
            color={theme.colors.white.normalActive}
          />
        </div>
        {!isLoading && <Heading
          title={(credits ?? 0)}
          fontSize="24px"
          fontWeight="700"
          color={theme.colors.white.normal}
        />}
      </div>
      <StyledButton
        title="payments.getCredits"
        onClick={() => navigate("/payments")}
      />
    </StyledCard>
  );
};

export default GetCreditsCard;
