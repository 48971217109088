// RegistrationForm.js
import React from "react";
import "./signUp.css";
import SignUp from "../../section/signUp";

const SignUpPage = () => {
  return (
    <SignUp />
  )
};

export default SignUpPage;
