import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { Icon } from "@iconify/react";
import logo from "@assets/newLogo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "@context/AuthContext";
import LogOutModal from "@components/modal/LogOutModal";
import { useTranslation } from "react-i18next";
import CopyModal from "@components/modal/CopyModal";
import ContactUsModal from "../../section/profileSection/contactUsModal";
import { theme } from "@styles/themes";
import styled from "styled-components";

// Styled Components
const SidebarContainer = styled.div`
  height: 100vh;
  padding: 20px;
  border-right: 0.2px solid #c7c7c7;
  position: sticky;
  top: 0;
`;

const Logo = styled.img`
  width: 8rem;
`;

const NavWrapper = styled(Nav)`
  display: flex;

  flex-direction: column;
  margin-top: 16px;
  padding-top: 16px;
`;

const NavItemWrapper = styled(Nav.Item)`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ActiveIndicator = styled.div<{ isActive: boolean }>`
  position: absolute;
  left: -6px;
  width: 4px;
  height: 100%;
  background: ${({ isActive }) =>
    isActive
      ? "linear-gradient(153.43deg, #015449 0%, #04F1D1 83.33%)"
      : "transparent"};
  border-radius: 0px 8px 8px 0px;
`;

const NavLinkWrapper = styled.div<{ isActive: boolean }>`
  background-color: ${({ isActive }) =>
    isActive ? theme.colors.grey.darkActive : "transparent"};
  display: flex;
  border-radius: 8px;
  align-items: center;
  margin-left: 10px;
  padding: 0px 10px;
  width: 100%;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ isActive }) =>
      isActive ? theme.colors.grey.darkActive : theme.colors.grey.darkActive};
  }
`;

const NavLinkText = styled(Nav.Link)<{ isActive: boolean }>`
  color: ${({ isActive }) =>
    isActive ? theme.colors.main.normal : theme.colors.white.normal};
  padding-left: 0;
  border-radius: 8px;
  padding: 10px 15px;
  z-index: 1;
  position: relative;
  width: 100%;
  transition: background-color 0.3s ease;
  &:hover,
  &:focus,
  &:active {
    color: ${({ isActive }) =>
      isActive ? theme.colors.main.normal : theme.colors.white.normal};
  }
`;

const BottomNavWrapper = styled(Nav)`
  position: absolute;
  bottom: 10px;
  display: flex;
  flex-direction: column;
`;

const BottomNavItemWrapper = styled(Nav.Item)`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const BottomNavLinkWrapper = styled.div`
  background-color: transparent;
  display: flex;
  border-radius: 8px;
  align-items: center;
  width: 100%;
`;

const BottomNavLinkText = styled(Nav.Link)<{ isLogout: boolean }>`
  color: ${({ isLogout }) => (isLogout ? "#F54242" : "#FFFFFF")};
  padding: 10px 15px 10px 8px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 8px;
  z-index: 1;
  position: relative;
  width: 100%;
  transition: background-color 0.3s ease;
  &:hover,
  &:focus,
  &:active {
    color: ${({ isLogout }) =>
      isLogout ? "#F54242" : theme.colors.white.normal};
    font-weight:700
  }
`;

const SideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const pathname = location?.pathname;
  const [logOut, setLogOut] = useState<boolean>(false); // Track the selected option
  const [contactOpen, setContactOpen] = useState<boolean>(false); // Track the selected option
  const { resetAuth } = useAuth();

  const Paths = [
    { name: t("sidebar.dashboard"), link: "/profile" },
    { name: t("sidebar.stats"), link: "/stats" },
    { name: t("sidebar.payments"), link: "/payments" },
  ];
  const bottomPaths = [
    { name: t("sidebar.contactus"), link: "/stats" },
    { name: t("sidebar.logout"), link: "" },
  ];

  const handleClickLogout = () => {
    resetAuth();
  };

  return (
    <SidebarContainer>
      <Logo src={logo} alt="logo" />
      <NavWrapper>
        {Paths?.map((option, index) => (
          <NavItemWrapper key={index}>
            <ActiveIndicator isActive={pathname === option.link} />
            <NavLinkWrapper isActive={pathname.includes(option.link)} >
              <Icon
                icon={
                  index === 0
                    ? "majesticons:home-line"
                    : index === 1
                    ? "heroicons-outline:chart-bar"
                    : "heroicons:circle-stack"
                }
                color={pathname.includes(option.link) ? "#04F1D1" : "#FFFFFF"}
                width={32}
                style={{ marginRight: "2px" }}
              />
              <NavLinkText
                isActive={pathname.includes(option.link)}
                onClick={() => navigate(option.link)}
              >
                {t(`${option.name}`)}
              </NavLinkText>
            </NavLinkWrapper>
          </NavItemWrapper>
        ))}
      </NavWrapper>
      <BottomNavWrapper>
        {bottomPaths?.map((option, index) => (
          <BottomNavItemWrapper key={index}>
            <BottomNavLinkWrapper>
              <Icon
                icon={
                  index === 0
                    ? "material-symbols:mail-outline"
                    : "iconamoon:exit-fill"
                }
                color={index === 1 ? "#F54242" : "#FFFFFF"}
                width={24}
                style={{ marginLeft: "8px"}}
              />
              <BottomNavLinkText
                isLogout={index === 1}
                onClick={() => {
                  option.name === t("sidebar.logout")
                    ? setLogOut(true)
                    : setContactOpen(true);
                }}
              >
                {t(`${option.name}`)}
              </BottomNavLinkText>
            </BottomNavLinkWrapper>
          </BottomNavItemWrapper>
        ))}
      </BottomNavWrapper>
      <LogOutModal
        open={logOut}
        onActionClick={() => handleClickLogout()}
        onClose={() => setLogOut(false)}
      />
      <CopyModal
        open={contactOpen}
        setOpen={setContactOpen}
        heading="profile.contactUs"
        mainContentStyle={{ padding: "0" }}
        mainContent={<ContactUsModal setContactOpen={setContactOpen} />}
      />
    </SidebarContainer>
  );
};

export default SideBar;
