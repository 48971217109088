import { theme } from "@styles/themes";
import React from "react";
import styled from "styled-components";
import { terms } from '@utils/termsAndConditions';

interface DivProps {
  position?: boolean;
}

const ScrollableContainer = styled.div`
height: 70vh;
max-width: 750px;
overflow-y: auto;
`;

export const CheckboxWrapper = styled.div<DivProps>`
  display: flex;
  align-items: center;
  margin: 12px 0;
  position: ${props => props.position && 'fixed'};
  bottom: 40px;
`; 

export const Checkbox = styled.input.attrs({ type: "checkbox" })`
  appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid white;
  position: relative;
  cursor: pointer;

  &:checked {
    background-color: ${theme.colors.main.normal}; /* Custom check color */
    border: 1px solid black;
  }

  &:checked::after {
    content: "";
    position: absolute;
    top: 1px;
    left: 4px;
    width: 5px;
    height: 9px;
    border: solid black;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

export const Label = styled.label`
  margin-left: 8px;
  margin-bottom: 2px;
  font-size: 14px;
  cursor: pointer;
  color: ${theme.colors.white.normal};
`;

export const ErrorText = styled.span`
  color: ${theme.colors.error};
  font-size: 12px;
  margin-top: 4px;
  display: block;
`;

const Body = styled.p`
font-family: ${theme.typography.fontFamily.primary};
color: ${theme.colors.white.normal};
font-size: 14px;
font-weight: 400;
line-height: 19.07px;
text-align: left;
white-space: pre-line;
padding-bottom: 80px;
margin-right: 4px;
`;

const StepFive = ({ control, errors }: any) => {
  return (
    <div>
      <ScrollableContainer>
      <Body>
        {terms}
      </Body>
      </ScrollableContainer>
      {errors.termsConditions && (
        <ErrorText>{errors.termsConditions.message}</ErrorText>
      )}
    </div>
  );
};

export default StepFive;
